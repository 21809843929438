import apiClient from '../apiClient'

const API_URL_RELATION = '/api/v1/relation_products_insurers'

// Get all insured products
const getAllInsuredProducts = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.get(`${API_URL_RELATION}/products`, config)
    
    const data = response.data

    return data
}

// Get all insurers with products
const getAllInsurersWithProducts = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.get(`${API_URL_RELATION}/insurers`, config)

    const data = response.data
 
    return data
}

// Get all insurers with products - excluding products details
const getOnlyAllInsurersWithProducts = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.get(`${API_URL_RELATION}/insurers_only`, config)

    const data = response.data
 
    return data
}

// Get product insurers
const getProductInsurers = async (productId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.get(`${API_URL_RELATION}/products/${productId}`, config)

    const data = response.data
 
    return data
}

// Get an insurer with products
const getInsurerWithProducts = async (insurerId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.get(`${API_URL_RELATION}/insurers/${insurerId}`, config)

    const data = response.data
    
    return data
}

const insurerService = {
    getProductInsurers,
    getAllInsuredProducts,
    getInsurerWithProducts,
    getAllInsurersWithProducts,
    getOnlyAllInsurersWithProducts
}
export default insurerService