import apiClient from '../apiClient'
const API_URL_CHECKOUT = '/api/v1/checkout'

// Add checkout
const addCheckout = async (checkoutDetails, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await apiClient.post(API_URL_CHECKOUT, checkoutDetails, config)

    const data = response.data

    return data 
}

// Get checkout
const getCheckout = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await apiClient.get(API_URL_CHECKOUT, config)

    const data = response.data

    return data 
}

// Delete checkout
const deleteCheckout = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await apiClient.delete(API_URL_CHECKOUT, config)

    const data = response.data

    return data 
}

const checkoutService = {
    getCheckout,
    addCheckout,
    deleteCheckout,
}

export default checkoutService