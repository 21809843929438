import apiClient from '../apiClient'

const API_URL_PRODUCTS = '/api/v1/products'
const API_URL_PRODUCTS_INSERT = '/api/v1/products/many'
const API_URL_PRODUCTS_ACTIVE = '/api/v1/products/active'

// Get all Products
const getProducts = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.get(API_URL_PRODUCTS, config)

    const data = response.data

    return data
}

// Get all Active Products
const getActiveProducts = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.get(API_URL_PRODUCTS_ACTIVE, config)

    const data = response.data

    return data
}

// Create a new product
const createProduct = async (newProduct, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.post(API_URL_PRODUCTS, newProduct, config)
    
    const data = response.data
    
    return data
}

// Insert new products
const insertProducts = async (newProducts, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    
    const response = await apiClient.post(API_URL_PRODUCTS_INSERT, newProducts, config)
    
    const data = response.data
    
    return data
}

// Get a product
const getProduct = async (productID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.get(`${API_URL_PRODUCTS}/${productID}`, config)

    const data = response.data
    
    return data
}

// Update a product
const updateProduct = async (product, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.post(`${API_URL_PRODUCTS}/${product._id}`, product, config)

    const data = response.data
    
    return data
}

// Update a product
const deleteProduct = async (productID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.delete(`${API_URL_PRODUCTS}/${productID}`, config)

    const data = response.data
    
    return data
}

const productService = {
    getProduct,
    getProducts,
    createProduct,
    updateProduct,
    deleteProduct,
    insertProducts,
    getActiveProducts
}
export default productService