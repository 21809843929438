import apiClient from '../apiClient'

const API_URL_PRODUCTS_RELATIONS = '/api/v1/products_with_supplierListings'

// Get all suppliers with supplier listings
const getProductWithSupplierListings = async (productId) => {
    const response = await apiClient.get(`${API_URL_PRODUCTS_RELATIONS}/${productId}`)

    const data = response.data

    return data
}

// Get a supplier with supplier listings
const getProductsWithSupplierListings = async () => {
    const response = await apiClient.get(API_URL_PRODUCTS_RELATIONS)

    const data = response.data

    return data
}

const productsWithSupplierListingsService = {
    getProductWithSupplierListings,
    getProductsWithSupplierListings,
}
export default productsWithSupplierListingsService