import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'
import WarningIcon from '@mui/icons-material/Warning'
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined'
import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import { 
  resetCart,
  setCounter,
  updateCart,
} from '../../features/cart/cartSlice'
import { 
  addCheckout,
  resetCheckout,
} from '../../features/checkout/checkoutSlice'
import CartListings from '../../components/cart/CartListings'
import EmptyCart from '../../components/cart/EmptyCart'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMOrderComputation from '../../components/common/layout/HMOrderComputation'
import HMStoreOption from '../../components/common/layout/HMStoreOption'
import HMLoginFirst from '../../components/common/layout/HMLoginFirst'
import HMStoreData from '../../components/common/layout/HMStoreData'
import HMModal from '../../components/common/layout/HMModal'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMRadioButton from '../../components/common/tools/HMRadioButton'
import HMTextField from '../../components/common/tools/HMTextField'
import HMCheckbox from '../../components/common/tools/HMCheckbox'
import HMDropZone from '../../components/common/tools/HMDropZone'
import HMButton from '../../components/common/tools/HMButton'
import HMSpiner from '../../components/common/tools/HMSpiner'
import HMText from '../../components/common/tools/HMText'
import {
  fontSize,
  iconStyle,
  textStyle,
} from '../../components/common/tools/Styles'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {
  cloudinary,
  currencies,
  storeDelivery,
  getStockAvailability,
  excludeKeysFromObject,
  MAX_PURCHASE_PER_UNIT
} from '../../hooks/helperFunctions'
import {
  red,
  gray,
  blue,
  white,
  green,
  orange,
  darkBlue,
  lightBlue,
  lightBlack,
  lightGreen,
  superLightBlue,
  seeThroughLightBlue,
} from '../../hooks/useColors'

const computeCartData = (cartDetails) => {
  let total = 0 
  let subtotal = 0
  let deliveryCost = 0
  let numberOfStores = 0 
  for (let cart of cartDetails) {
    const tempCartItems = cart.cartItems.filter(item => item.select)
    if (tempCartItems.length) {
      numberOfStores++
      deliveryCost += cart.deliveryOption.cost
      for (let item of tempCartItems) {
        if (item.select) {
          const cost = (item.quantity * item.onlinePrice) 
          subtotal += cost
          total += cost
        }
      }
    }
  }
  total += deliveryCost
  return {
    total,
    subtotal,
    deliveryCost,
    numberOfStores
  }
}
const findSelectedItems = (cartDetails) => {
  let tempSelectedItems = []
  for (let cart of cartDetails) {
    for (let item of cart.cartItems) {
      if (item.select) {
        tempSelectedItems.push({
          ...item,
          store: cart.store
        })
      }
    }
  }
  return tempSelectedItems
}
const countSelectedItems = (cartDetails) => {
  let count = 0
  for (let cart of cartDetails) {
    for (let item of cart.cartItems) {
      if (item.select) {
        count++
      }
      if (count > 1) {
        break
      }
    }
  }
  return count
}
const extractCheckoutData = (cartDetails) => {
  let items = 0       
  let checker = 0
  const checkoutDetails = []
  cartDetails.forEach(cart => {
    const checkoutItems = []
    cart.cartItems.forEach(item => {
      if (item.select) {
        items++
        if (
          (item.classification.includes('health')) || 
          (item.classification.includes('otc') && item.addedInfo && Object.keys(item.addedInfo).length) || 
          (item.classification.includes('prescription') && item.addedInfo && item.addedInfo.uploadedPrescription)
        ) {
          checker++
          checkoutItems.push(item)
        } 
      }
    })
    if (checkoutItems.length) {
      checkoutDetails.push({
        ...excludeKeysFromObject(cart, ['cartItems']),
        checkoutItems
      })
    }
  })
  
  if (items > 0 && checker === items) {
    return {
      ready: true,
      checkoutDetails
    }
  } else {
    return {
      ready: false,
      checkoutDetail: []
    }
  }
}
const updateQuantiy = (cartDetails, listingId, newQuantity) => {
  return cartDetails.reduce((result, data) => {
    let subtotal = data.subtotal
    let storeQuantity = data.quantity
    const cartItems = data.cartItems.map((orderItem) => {
      if (orderItem.listing === listingId) {
        const diff = newQuantity - orderItem.quantity
        storeQuantity += diff
        subtotal += diff * orderItem.onlinePrice
        return {
          ...orderItem,
          quantity: newQuantity
        }
      } else {
        return orderItem
      }
    })
    
    result.push({
      ...data,
      subtotal,
      cartItems,
      quantity: storeQuantity,
    })

    return result
  }, [])
}
const findStoreWithSelectedItems = (cartDetails, publicStores) => {
  const storesCartsObj = {}
  const selectedStoresObj = {}
  let tempSelcetedItemsObj = {}
  const selectedProductObj = {}
  const storesToConsiderObj = {}
  const tempUnselectedCartDetails = []
  const recommendedStoreObj = {
    cost: 0,
    store: '',
  }

  cartDetails.forEach((cartDetail => {
    const cartItemsObj = {}
    let unselectedQuantity = 0
    const unselectedCartItems = []
    cartDetail.cartItems.forEach(cartItem => {
      if (cartItem.select) {
        cartItemsObj[cartItem.listing] = cartItem
        selectedProductObj[cartItem.product] = {
          ...cartItem,
          addedInfo: (
              selectedProductObj[cartItem.product] && 
              'addedInfo' in selectedProductObj[cartItem.product] && 
              Object.keys(selectedProductObj[cartItem.product].addedInfo).length
            ) ? selectedProductObj[cartItem.product].addedInfo : 'addedInfo' in cartItem ? cartItem.addedInfo : {},
          quantity: selectedProductObj[cartItem.product] 
            ? selectedProductObj[cartItem.product].quantity + cartItem.quantity 
            : cartItem.quantity 
        }
      } else {
        unselectedQuantity += cartItem.quantity
        unselectedCartItems.push(cartItem)
      }
    })
    if (Object.keys(cartItemsObj).length) {
      selectedStoresObj[cartDetail.store] = {
        ...cartDetail,
        cartItems: cartItemsObj
      }
    } 
    if (unselectedCartItems.length) {
      tempUnselectedCartDetails.push({
        ...cartDetail,
        quantity: unselectedQuantity,
        cartItems: unselectedCartItems
      })
    }
  })) 

  const storeObj = { ...selectedStoresObj }
  for (let i = 0; i < publicStores.length; i++) {
    const storeId = publicStores[i].store._id
    if (!Object.keys(storeObj).length) {
      break
    } 
    
    if (storeId in storeObj) {
      storesToConsiderObj[storeId] = publicStores[i]
      delete storeObj[storeId]
    }
  }
  
  const productObj = { ...selectedProductObj }
  for (let key in storesToConsiderObj) {
    let count = 0
    let quantity = 0
    let subtotal = 0
    const cartItems = []
    const storeData = storesToConsiderObj[key]
    for (let i = 0; i < storeData.productsAndListings.length; i++) {
      const data = storeData.productsAndListings[i]
      const dbProduct = data.product
      const dbListing = data.storeListing
      const selectListing = selectedProductObj[dbProduct._id]
      
      if (!Object.keys(productObj).length) {
        break
      } 

      const db_availableStock = getStockAvailability(dbListing.availableStock, dbListing.transitStockCount)
      if (selectListing && (db_availableStock.stockCount > selectListing.quantity)) {
        count++
        quantity += selectListing.quantity
        subtotal += (dbListing.onlinePrice * selectListing.quantity) 
        tempSelcetedItemsObj[dbListing._id] = 1
        cartItems.push({
          ...selectListing,
          listing: dbListing._id,
          onlinePrice: dbListing.onlinePrice,
        })
      }
      if (dbListing._id in storeObj) {
        delete productObj[dbProduct._id]
      }
    }
    if (count === Object.keys(selectedProductObj).length) {
      tempSelcetedItemsObj = { ...tempSelcetedItemsObj }
      const storeId = storeData.store._id
      storesCartsObj[storeId] = {
        ...selectedStoresObj[storeId],
        quantity,
        subtotal,
        cartItems,
        select: false,
        store: storeData.store
      }

      if (subtotal < recommendedStoreObj.cost) {
        recommendedStoreObj['store'] = storeData.store._id
      } else if (recommendedStoreObj.cost === 0) {
        recommendedStoreObj['cost'] = subtotal
        recommendedStoreObj['store'] = storeData.store._id
      }
    } 
  }

  return {
    storesCartsObj,
    tempSelcetedItemsObj,
    tempUnselectedCartDetails,
    recommendedStore: recommendedStoreObj['store']
  }
}
const validateQuantity = (stockCount, quantity) => {
  let validQuantity = quantity
  if (stockCount <= validQuantity) {
    validQuantity = 
      validQuantity >= MAX_PURCHASE_PER_UNIT && MAX_PURCHASE_PER_UNIT < stockCount
      ? MAX_PURCHASE_PER_UNIT
      : stockCount 
    
  } else if (validQuantity > MAX_PURCHASE_PER_UNIT) {
    validQuantity = MAX_PURCHASE_PER_UNIT
  }

  return validQuantity
}

const currency = currencies.find(curr => curr.country === 'Rwanda').currency

function Cart() {
  const navigate = useNavigate()
  const dispatch = useDispatch() 
  const { 
    visible,
  } = useSelector((state) => state.nav)
  const { 
    user 
  } = useSelector((state) => state.auth)
  const { 
    cart,
    counter,
    isAdded,
    cartData,
    isUpdated,
    isLoading,
  } = useSelector((state) => state.cart)
  const { 
    publicStores
  } = useSelector((state) => state.storesWithListings)
  const checkoutState = useSelector((state) => state.checkout)

  const {
    windowW,
    windowH,
  } = useWindowDimensions()

  const isTabletScreen = windowW < 600 ? true : false
  const isMediumScreen = windowW < 1110 ? true : false
  const isNormalTabletScreen = windowW < 880 ? true : false
  const isBigHeightScreen = windowH < 1000 ? true : false
  const isNormalHeightScreen = windowH < 650 ? true : false
  const isSmallHeightScreen = windowH < 400 ? true : false
  const isVerySmallHeightScreen = windowH < 300 ? true : false
  
  const tax = 0
  // const [tax, setTax] = useState(0)
  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [isUpload, setIsUpload] = useState(false)
  const [isAddInfo, setIsAddInfo] = useState(false)
  const [cartDetails, setCartDetails] = useState([])
  const [checkoutData, setCheckoutData] = useState([])
  const [isCartEmpty, setIsCartEmpty] = useState(false)
  const [isUpdateCart, setIsUpdateCart] = useState(false)
  const [orderSummaryTop, setOrderSummaryTop] = useState(0)
  const [selectedStoreId, setSelectedStoreId] = useState('')
  const [updatingListing, setUpdatingListing] = useState('')
  const [selcetedItemsObj, setSelcetedItemsObj] = useState({})
  const [isUpdatingCart, setIsUpdatingCart] = useState(false)
  const [selectedStoresObj, setSelectedStoresObj] = useState({})
  const [recommendedStoreId, setRecommendedStoreId] = useState('')
  const [isAnyItemSelected, setIsAnyItemSelected] = useState(false)
  const [prescriptionFileUrl, setPrescriptionFileUrl] = useState('')
  const [unselectedCartDetails, setUselectedCartDetails] = useState({})
  const [isBuyFromSingleStore, setIsBuyFromSingleStore] = useState(false)
  const [deliveryType, setDeliveryType] = useState(storeDelivery[0].type)
  const [isRemoveAddInfoSelection, setIsRemoveAddInfoSelection] = useState(false)
  const [cartComputation, setCartComputation] = useState({
    total: 0,
    subtotal: 0,
    deliveryCost: 0,
    numberOfStores: 0
  })
  const [formData, setFormData] = useState({
    other: '',
    patientAge: 16,
    firstTimeUse: true,
    hasPrescription: false,
    causedConditions: false,
    isPatientPregnant: false,
    preExistingConditions: 'None',
  })
  const {
    other,
    patientAge,
    firstTimeUse,
    hasPrescription,
    causedConditions,
    isPatientPregnant,
    preExistingConditions
  } = formData
  const {
    total,
    subtotal,
    deliveryCost,
    numberOfStores
  } = cartComputation
 
  const breadOptions = [
    { text: 'Home', link: '/landing' },
    { text: 'Cart', link: null }
  ]

  const defaultCartDetails = () => {
    let requiresPrescription = false
    let requiresAdditionalInfo = false
    setCartDetails(cartData.map(data => {
      return {
        ...data,
        cartItems: data.cartItems.map(item => {
          if (requiresPrescription) {
            return item
          }
          if (
            item.classification.includes('prescription') && 
            (!('addedInfo' in item) || ('addedInfo' in item && !Object.keys(item.addedInfo).length)) 
          ) {
            requiresPrescription = true
          } else if (
            item.classification.includes('otc') && 
            (!('addedInfo' in item) || ('addedInfo' in item && !Object.keys(item.addedInfo).length)) 
          ) { 
            requiresAdditionalInfo = true
          }
          return item
        })
      }
    }))

    if (requiresPrescription) {
      setOpen(true)
      setIsUpload(true)
      setTitle('Upload Prescription')
    } else if (requiresAdditionalInfo) {
      setOpen(true)
      setIsAddInfo(true)  
      setTitle('Additional Information')
    }
  }

  useEffect(() => {
    if (!cartDetails.length) {
      defaultCartDetails()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (counter === 1 && !cartDetails.length) {
      defaultCartDetails()
      dispatch(setCounter(0))
    }
    // eslint-disable-next-line
  }, [counter])

  useEffect(() => {
    if (isRemoveAddInfoSelection) {
      setCartDetails(cartDetails.map(cart => ({
        ...cart,
        cartItems: cart.cartItems.map(item => ({
          ...item,
          addInfoSelect: false
        }))
      })))

      setIsRemoveAddInfoSelection(false)
    }
    // eslint-disable-next-line
  }, [isRemoveAddInfoSelection])

  useEffect(() => {
    if (isUpdateCart) {
      const cartItems = []
      cartDetails.forEach(cartDetail => {
        cartDetail.cartItems.forEach(item => {
          const cartItem = {
            storeListing: item.listing,
            quantity: validateQuantity(item.stockCount, item.quantity), 
          }
          if ('addedInfo' in item && Object.keys(item.addedInfo).length) {
            cartItem['addedInfo'] = item.addedInfo
          }
          cartItems.push(cartItem)
        })
      })


      const tempCartDetails = cartDetails.reduce((result, data) => {
        let subtotal = data.subtotal
        let storeQuantity = data.quantity
        const items = data.cartItems.map((orderItem) => {
          const validQuantity = validateQuantity(orderItem.stockCount, orderItem.quantity)
          if (validQuantity !== orderItem.quantity) {
            const diff = validQuantity - orderItem.quantity
            storeQuantity += diff
            subtotal += diff * orderItem.onlinePrice
            return {
              ...orderItem,
              quantity: validQuantity
            }
          } 
          return orderItem
        })
        
        result.push({
          ...data,
          subtotal,
          cartItems: items,
          quantity: storeQuantity,
        })

        return result
      }, [])
      
      dispatch(updateCart({cartItems}))
      setIsUpdateCart(false)
      setCartDetails(tempCartDetails)
    }
    // eslint-disable-next-line
  }, [isUpdateCart])

  useEffect(() => {
    if (cartDetails.length) {
      setSelectedStoreId('')
      setCartComputation(computeCartData(cartDetails))
      setCheckoutData(extractCheckoutData(cartDetails))
      const tempSelectedItems = findSelectedItems(cartDetails)
      setIsAnyItemSelected(tempSelectedItems.length ? true : false)
      if (publicStores.length) {
        const {
          storesCartsObj,
          recommendedStore,
          tempSelcetedItemsObj,
          tempUnselectedCartDetails,
        } = findStoreWithSelectedItems(cartDetails, publicStores)
        setSelectedStoresObj(storesCartsObj)
        setRecommendedStoreId(recommendedStore)
        setSelcetedItemsObj(tempSelcetedItemsObj)
        setUselectedCartDetails(tempUnselectedCartDetails)
      }
    } 
    setIsCartEmpty(!cartDetails.length ? true : false)
    // eslint-disable-next-line
  }, [cartDetails])

  useEffect(() => {
    if (isUpdated || isAdded) {
      dispatch(resetCart())
      setUpdatingListing('')
      setIsUpdatingCart(false) 
    }

    if (checkoutState.isAdded) {
      dispatch(resetCheckout())
      navigate('/checkout')
    }
    // eslint-disable-next-line
  }, [isUpdated, checkoutState, isAdded])

  const handleAddInfoCheckboxChange = (e, listingId) => {
    const checked = e.target.checked
    setCartDetails(cartDetails.map(cart => ({
      ...cart,
      cartItems: cart.cartItems.map(item => ({
        ...item,
        addInfoSelect: item.listing === listingId ? checked : item.addInfoSelect
      }))
    })))
  }

  const handleStoreCheckboxChange = (e, store) => {
    const checked = e.target.checked
    setCartDetails(cartDetails.map((cart) => ({
      ...cart,
      select: cart.store === store ? checked : cart.select,
      cartItems: cart.store === store ? cart.cartItems.map(item => ({
        ...item,
        select: checked
      })) : cart.cartItems,
    })))
  }
  const handleItemCheckboxChange = (e, listingId) => {
    const checked = e.target.checked
    setCartDetails(cartDetails.map(cart => ({
      ...cart,
      cartItems: cart.cartItems.map(item => ({
        ...item,
        select: item.listing === listingId ? checked : item.select
      }))
    })))
  }
  const handleCheckAll = (e) => {
    const checked = e.target.checked
    setCartDetails(cartDetails.map(cart => ({
      ...cart,
      select: checked,
      cartItems: cart.cartItems.map(item => ({
        ...item,
        select: checked
      }))
    })))
  }

  const navegateToProductPage = (item) => {
    navigate(`/categories/${item.categoryParentId}/${item.categoryId}/${item.product}`)
  }
  const navegateToStorePage = (storeId) => {
    navigate(`/stores/${storeId}`)
  }

  const handleQuantityIncrease = (item) => {
    if (item.stockCount > item.quantity) {
      setUpdatingListing(item.listing)
      setCartDetails(updateQuantiy(cartDetails, item.listing, item.quantity + 1))
      setIsUpdateCart(true)
    }
  }
  const handleQuantityReduce = (item) => {
    if (item.quantity > 1) {
      setUpdatingListing(item.listing)
      setCartDetails(updateQuantiy(cartDetails, item.listing, item.quantity - 1))
      setIsUpdateCart(true)
    }
  }
  const handleQuantityChange = (e, item) => {
    const value = parseInt(e.target.value)

    if (value > 0) {
      setCartDetails(updateQuantiy(cartDetails, item.listing, value))
      setIsUpdateCart(true)
    }
  }

  const handlePrescriptionModal = (data) => {
    const listingId = data.listing
    const tempCartDetails = cartDetails.map(cart => ({
      ...cart,
      cartItems: cart.cartItems.map(item => ({
        ...item,
        addInfoSelect: item.listing === listingId ? true : item.addInfoSelect
      }))
    }))
    setCartDetails(tempCartDetails)

    if (('addedInfo' in data) && data.addedInfo.uploadedPrescription) {
      setPrescriptionFileUrl(data.addedInfo.prescription)
    }

    setOpen(true)
    setIsUpload(true)
    setTitle('Upload Prescription')
  }
  const handlePrescription = () => {
    const addedInfo = {
      prescription: prescriptionFileUrl,
      uploadedPrescription: true,
    } 

    handleClose()
    setCartDetails(cartDetails.map(cartDetail => ({
        ...cartDetail,
        cartItems: cartDetail.cartItems.map(cartItem => (
          cartItem.addInfoSelect ? {
            ...cartItem,
            addedInfo
          } : cartItem
        ))
    })))
    
    setIsUpdateCart(true)
  }
  
  const handleAddInfoModal = (data) => {
    const listingId = data.listing
    const tempCartDetails = cartDetails.map(cart => ({
      ...cart,
      cartItems: cart.cartItems.map(item => ({
        ...item,
        addInfoSelect: item.listing === listingId ? true : item.addInfoSelect
      }))
    }))
    setCartDetails(tempCartDetails)

    if (('addedInfo' in data) && data.addedInfo.uploadedPrescription) {
      setPrescriptionFileUrl(data.addedInfo.prescription)
      setFormData((prevState) => ({
          ...prevState,
          hasPrescription: true,
      }))
    } else if ('addedInfo' in data) {
      setFormData({
        other: data.addedInfo.other || '',
        patientAge: data.addedInfo.patientAge,
        firstTimeUse: data.addedInfo.firstTimeUse,
        causedConditions: data.addedInfo.causedConditions,
        isPatientPregnant: data.addedInfo.isPatientPregnant,
        hasPrescription: data.addedInfo.uploadedPrescription,
        preExistingConditions: data.addedInfo.preExistingConditions
      })
    } 
    
    setOpen(true)
    setIsAddInfo(true)
    setTitle('Additional Information')
  }
  const handleAddInfo = () => {
    const tempAddedInfo = {
      patientAge,
      firstTimeUse,
      causedConditions,
      isPatientPregnant,
      preExistingConditions,
      uploadedPrescription: hasPrescription
    }
    const addedInfo = hasPrescription ? {
      prescription: prescriptionFileUrl,
      uploadedPrescription: hasPrescription,
    } : preExistingConditions === 'Other' ? {
      ...tempAddedInfo,
      other
    } : tempAddedInfo

    handleClose()
    setCartDetails(cartDetails.map(cartDetail => ({
      ...cartDetail,
      cartItems: cartDetail.cartItems.map(cartItem => (
        cartItem.addInfoSelect ? {
          ...cartItem,
          addedInfo
        } : cartItem
      ))
    })))

    setIsUpdateCart(true)
  }

  const handleCartDeletionModal = () => {
    setOpen(true)
    setIsDelete(true)
    countSelectedItems(cartDetails)
    setTitle(countSelectedItems(cartDetails) > 1 ? 'Delete Cart Items' : 'Delete Cart Item')
  }
  const handleCartDeletion = () => {
    const updatedCartDetails = []
    for (let cart of cartDetails) {
      const cartItems = []
      if (!cart.select) {
        for (let item of cart.cartItems) {
          if (!item.select) {
            cartItems.push(item)
          }
        }
        updatedCartDetails.push({
          ...cart,
          cartItems
        })
      }
    }

    handleClose()
    setCartDetails(updatedCartDetails)
    setIsUpdateCart(true)
  }

  const handleBuyFromSingleStoreModal = () => {
    setOpen(true)
    setIsBuyFromSingleStore(true)
    setTitle('Choose a store you want to buy from')
    setSelectedStoreId(selectedStoreId ? selectedStoreId : recommendedStoreId) 
  }
  const handleSelectedStoreChange = (e) => {
    setSelectedStoreId(e.target.value)
  }
  const handleBuyFromSingleStore = () => {
    let updatedCartDetails = []
    if (unselectedCartDetails.length) {
      let foundMatch = false
      unselectedCartDetails.forEach(cartDetail => {
        if (cartDetail.store === selectedStoreId) {
          foundMatch = true
          let quantity = 0
          const cartItems = []
          let tempSubtotal = 0
          cartDetail.cartItems.forEach(cartItem => {
            if (!(cartItem.listing in selcetedItemsObj)) {
              cartItems.push(cartItem)
              quantity += cartItem.quantity
              tempSubtotal += (cartItem.quantity * cartItem.onlinePrice)
            }
          })
          updatedCartDetails.push({
            ...selectedStoresObj[selectedStoreId],
            store: selectedStoreId,
            quantity: selectedStoresObj[selectedStoreId].quantity + quantity,
            subtotal: selectedStoresObj[selectedStoreId].subtotal + tempSubtotal,
            cartItems: [
              ...cartItems,
              ...selectedStoresObj[selectedStoreId].cartItems,
            ]
          })
        } else {
          updatedCartDetails.push(cartDetail)
        }
      })
      if (!foundMatch) {
        updatedCartDetails.push({
          ...selectedStoresObj[selectedStoreId],
          store: selectedStoreId
        })
      }
    } else {
      updatedCartDetails = [{
        ...selectedStoresObj[selectedStoreId],
        store: selectedStoreId
      }]
    }

    setCartDetails(updatedCartDetails)
    setIsUpdateCart(true)
    handleClose()
  }

  const handleProceedToCheckout = () => {
    if (checkoutData.ready) {
      const checkoutDetails = checkoutData.checkoutDetails
      
      dispatch(addCheckout({ checkoutDetails }))
    }
  }

  const handleClose = () => {
    if (isUpload || isAddInfo) {
      setIsRemoveAddInfoSelection(true)
    }
    setTitle('')
    setOpen(false)
    setIsDelete(false)
    setIsUpload(false)
    setIsAddInfo(false)
    setPrescriptionFileUrl('')
    setIsBuyFromSingleStore(false)
  }
  const askForPresription = {
    question: 'Do you have a prescription?',
    choices: [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]
  }
  const askIfPresription = {
    question: 'Is the patient pregnant?',
    choices: [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]
  }
  const askIfFirstTimeUse = {
    question: 'Is it the first time using this medication?',
    choices: [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]
  }
  const askIfCausedConditions = {
    question: 'Did this medication cause you any condition such as allergies?',
    choices: [
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ]
  }
  const askPrexistignConditions = {
    question: 'Patient pre-existing conditions',
    choices: [
      { label: 'None', value: 'None' },
      { label: 'Allegies', value: 'Allegies' },
      { label: 'Other', value: 'Other' },
    ]
  }

  const handleChange = (e) => {
    let value = e.target.value
    const name = e.target.name

    if (name === 'patientAge' && (value > 130 || value < 0) ) {
      value = patientAge
    } else if (name === 'firstTimeUse') {
      value = (value === true || value === 'true') ? true : false
    } else if (name === 'hasPrescription') {
      value = (value === true || value === 'true') ? true : false
    } else if (name === 'causedConditions') {
      value = (value === true || value === 'true') ? true : false
    } else if (name === 'isPatientPregnant') {
      value = (value === true || value === 'true') ? true : false
    }

    setFormData((prevState) => ({
        ...prevState,
        [name]: value,
    }))
  }

  const supportedFiles = {
    'image/png': [],
    'image/jpg': [],
    'image/jpeg': [],
    'application/pdf': [],
  }

  const handleNormalDelivery = () => {
    const deliveryOption = storeDelivery.find(data => data.type === storeDelivery[0].type)
    const tempCartDetails = cartDetails.map(cart => ({
      ...cart,
      deliveryOption,
      subtotal: cart.subtotal - cart.deliveryOption.cost + deliveryOption.cost,
    }))
    setCartDetails(tempCartDetails)
    setDeliveryType(storeDelivery[0].type)
    setCartComputation(computeCartData(tempCartDetails))
  }
  const handleUrgentDelivery = () => {
    const deliveryOption = storeDelivery.find(data => data.type === storeDelivery[1].type)
    const tempCartDetails = cartDetails.map(cart => ({
      ...cart,
      deliveryOption,
      subtotal: cart.subtotal - cart.deliveryOption.cost + deliveryOption.cost,
    }))
    setCartDetails(tempCartDetails)
    setDeliveryType(storeDelivery[1].type)
    setCartComputation(computeCartData(tempCartDetails))
  }

  const scrollListener = () => {
    setOrderSummaryTop(window.scrollY)
  }
  
  window.addEventListener('scroll', scrollListener)

  const deliveryTypeCost = storeDelivery.find(data => data.type === deliveryType).cost

  return user ? (     
    <Page 
      height={isCartEmpty && '80vh'}
      flexDirection={isCartEmpty && 'column'}
    >
      {(
        isUpdatingCart || 
        checkoutState.isLoading ||
        (Object.keys(cart).length && cart.cartItems.length && !cartDetails.length)
      ) ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer
        display='flex'
        margin='0 auto 10px auto'
      >
        <HMBreadcrumbs 
          options={breadOptions}
          margin='auto auto auto 0'
        />
        <HMBox 
          padding='0'
          float='right'
          display='flex'
        >
          <HMButton 
            type='button'
            bgColor={orange}
            isResponsive={true}
            margin='auto 0 auto auto'
            handleClick={() => navigate(-1)}
            text={<Typography sx={textStyle}>Back</Typography>} 
            icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
          />
        </HMBox>
      </FullWidthContainer>
      <HMModal 
        open={open} 
        title={title}
        maxWidth='600px'
        maxHeight='95vh'
        handleClose={handleClose} 
        colors={{lightBlack, lightBlue}}
      >
        {isDelete ? (
          <>
            <HMBox
              width='100%'
              display='flex'
              margin='10px 0'
              className='fade-in'
            >
              <WarningIcon 
                sx={{
                  padding: '0',
                  color: orange,
                  margin: 'auto 0 auto auto',
                }}
              />
              <Typography 
                sx={{
                  ...textStyle, 
                  fontSize: `${fontSize}px`,
                  margin: 'auto auto auto 10px',
                  textAlign: isTabletScreen && 'justify', 
                }}
              >
                Are your sure you want to remove the following&nbsp;
                {countSelectedItems(cartDetails) > 1 ? 'products' : 'product'}
                &nbsp;from your cart?
              </Typography>
            </HMBox>
            <HMBox
              padding='0'
              width='100%'
              overflowY='auto'
              className='fade-in'
              maxHeight={
                isVerySmallHeightScreen ? '30vh' 
                : isSmallHeightScreen ? '40vh' 
                : isNormalHeightScreen ? '50vh' 
                : isBigHeightScreen ? '60vh' 
                : '70vh'
              }
            >
              {cartDetails.map(cart => {
                return cart.cartItems.filter(item => item.select).map((item, index) => (
                  <CartListings 
                    key={index}
                    item={item}
                    cart={cart}
                    className='fade-in'
                    handleStore={() => navegateToStorePage(cart.store)}
                    handleProduct={() => navegateToProductPage(item)}
                  />
                ))
              })}
            </HMBox>
          </>
        ) : isUpload ? (
          <HMBox
            width='100%'
            display='flex'
            overflowY='auto'
            margin='20px 0 10px 0'
            flexDirection='column'
            className='HM-cart-info'
            minWidth={isTabletScreen && '80vw'}
            maxHeight={
              isVerySmallHeightScreen ? '40vh' 
              : isSmallHeightScreen ? '50vh' 
              : isNormalHeightScreen ? '60vh' 
              : isBigHeightScreen ? '70vh' 
              : '80vh'
            }
          >
            <HMBox 
              padding='0'
              width='100%'
              margin='10px 0'
            >
              <HMDropZone 
                allowedSizeInMB={1}
                className='drag-drop' 
                supportedFiles={supportedFiles} 
                cloudinaryFileUrl={prescriptionFileUrl}
                endPoint = {cloudinary.API_URL_PRESCRIPTION}
                setCloudinaryFileUrl = {setPrescriptionFileUrl}
              />
            </HMBox>
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='10px 0 0 0'
              flexDirection='column'
              bgColor={superLightBlue}
            >
              <HMText 
                width='100%'
                color={white}
                bgColor={blue}
                fontWeight={500}
                padding='5px 10px'
                margin='0 0 10px 0'
                borderBottomLeftRadius='0'
                borderBottomRightRadius='0'
                text='Select all products that applies :' 
                className={cartDetails.filter(cart => (
                  cart.cartItems.find(item => item.classification.includes('prescription'))
                )).length ? 'fade-in' : 'hide'}
              />
              {cartDetails.map(cart => {
                return cart.cartItems.filter(item => (
                  item.classification.includes('otc') ||
                  item.classification.includes('prescription') 
                )).map((item, index) => (
                  <CartListings 
                    key={index}
                    item={item}
                    cart={cart}
                    checkbox={true}
                    className='fade-in'
                    handleProduct={() => navegateToProductPage(item)}
                    handleStore={() => navegateToStorePage(cart.store)}
                    handleChange={(e) => handleAddInfoCheckboxChange(e, item.listing)}
                  />
                ))
              })}
            </HMBox>
          </HMBox>
        ) : isAddInfo ? (
          <HMBox
            width='100%'
            display='flex'
            overflowY='auto'
            margin='20px 0 10px 0'
            flexDirection='column'
            className='HM-cart-info'
            minWidth={isTabletScreen && '80vw'}
            maxHeight={
              isVerySmallHeightScreen ? '40vh' 
              : isSmallHeightScreen ? '50vh' 
              : isNormalHeightScreen ? '60vh' 
              : isBigHeightScreen ? '70vh' 
              : '80vh'
            }
          >
            <HMRadioButton 
              margin='auto 0'
              bgColor={white}
              padding='2px 5px'
              name='hasPrescription'
              value={hasPrescription}
              onChange={handleChange}
              fontSize={`${fontSize}px`}
              label={askForPresription.question}
              options={askForPresription.choices}
              labelId='ask-prescription-radio-group'
              flexDirection={isTabletScreen ? 'column' : 'row'}
            />
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              className={!hasPrescription ? 'fade-in' : 'hide'}
            >
              <HMText 
                text="Patient's Age"
                margin='auto auto auto 5px'
              />
              <HMTextField 
                width='110px'
                type='number'
                required={true}
                name='patientAge'
                borderRadius='8px'
                value={patientAge}
                bgColor={lightBlue}
                onChange={handleChange}
                margin={isTabletScreen ? '5px 0 10px 0' : 'auto 0 auto auto'}
              />
            </HMBox>
            <HMRadioButton 
              margin='auto 0'
              bgColor={white}
              padding='2px 5px'
              name='isPatientPregnant'
              value={isPatientPregnant}
              onChange={handleChange}
              fontSize={`${fontSize}px`}
              label={askIfPresription.question}
              options={askIfPresription.choices}
              labelId='ask-prescription-radio-group'
              flexDirection={isTabletScreen ? 'column' : 'row'}
              className={!hasPrescription ? 'fade-in' : 'hide'}
            />
            <HMRadioButton 
              margin='auto 0'
              bgColor={white}
              padding='2px 5px'
              radioWidth='160px'
              name='firstTimeUse'
              value={firstTimeUse}
              labelMargin='auto 0'
              labelWidth='max-content'
              onChange={handleChange}
              fontSize={`${fontSize}px`}
              label={askIfFirstTimeUse.question}
              options={askIfFirstTimeUse.choices}
              labelId='ask-prescription-radio-group'
              flexDirection={isTabletScreen ? 'column' : 'row'}
              className={!hasPrescription ? 'fade-in' : 'hide'}
              radioMargin={!isTabletScreen && 'auto 0 auto auto'}
            />
            <HMRadioButton 
              margin='auto 0'
              bgColor={white}
              padding='2px 5px'
              radioWidth='160px'
              labelMargin='auto 0'
              name='causedConditions'
              onChange={handleChange}
              labelWidth='max-content'
              value={causedConditions}
              fontSize={`${fontSize}px`}
              labelId='ask-prescription-radio-group'
              label={askIfCausedConditions.question}
              options={askIfCausedConditions.choices}
              flexDirection={isTabletScreen ? 'column' : 'row'}
              radioMargin={!isTabletScreen && 'auto 0 auto auto'}
              className={(!hasPrescription && !firstTimeUse) ? 'fade-in' : 'hide'}
            />
            <HMRadioButton 
              margin='auto 0'
              bgColor={white}
              padding='2px 5px'
              onChange={handleChange}
              fontSize={`${fontSize}px`}
              name='preExistingConditions'
              value={preExistingConditions}
              labelId='ask-prescription-radio-group'
              label={askPrexistignConditions.question}
              options={askPrexistignConditions.choices}
              flexDirection={isTabletScreen ? 'column' : 'row'}
              className={!hasPrescription ? 'fade-in' : 'hide'}
            />
            <HMTextField 
              type='text'
              width='100%'
              name='other'
              value={other}
              margin='5px 0'
              borderRadius='8px'
              bgColor={lightBlue}
              onChange={handleChange}
              label='Other conditions'
              placeholder='Enter other pre-existing conditions'
              className={(preExistingConditions === 'Other' && !hasPrescription) ? 'fade-in' : 'hide'}
            />
            <HMBox 
              padding='0'
              width='100%'
              margin='10px 0'
              className={hasPrescription ? 'fade-in' : 'hide'}
            >
              <HMDropZone 
                allowedSizeInMB={1}
                className='drag-drop' 
                supportedFiles={supportedFiles} 
                cloudinaryFileUrl={prescriptionFileUrl}
                endPoint = {cloudinary.API_URL_PRESCRIPTION}
                setCloudinaryFileUrl = {setPrescriptionFileUrl}
              />
            </HMBox>
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='10px 0 0 0'
              flexDirection='column'
              bgColor={superLightBlue}
            >
              <HMText 
                width='100%'
                color={white}
                bgColor={blue}
                fontWeight={500}
                padding='5px 10px'
                margin='0 0 10px 0'
                borderBottomLeftRadius='0'
                borderBottomRightRadius='0'
                text='Select all products that applies :' 
                className={
                  cartDetails.filter(cart => (
                    cart.cartItems.find(item => (
                      item.classification.includes('otc') || item.classification.includes('prescription')
                    ))
                  )).length ? 'fade-in' : 'hide'
                }
              />
              {cartDetails.map(cart => {
                const tempCartItems = cart.cartItems.filter(item => {
                  if (hasPrescription) {
                    return item.classification.includes('otc') || item.classification.includes('prescription')
                  } else {
                    return item.classification.includes('otc')
                  } 
                })
                return tempCartItems.map((item, index) => (
                  <CartListings 
                    key={index}
                    item={item}
                    cart={cart}
                    checkbox={true}
                    handleProduct={() => navegateToProductPage(item)}
                    handleStore={() => navegateToStorePage(cart.store)}
                    handleChange={(e) => handleAddInfoCheckboxChange(e, item.listing)}
                    className={
                      hasPrescription ||
                      (item.classification.includes('otc') && !hasPrescription) 
                      ? 'fade-in' : 'hide'
                    }
                  />
                ))
              })}
            </HMBox>
          </HMBox>
        ) : isBuyFromSingleStore ? (
          <>
            <HMBox
              width='100%'
              display='flex'
              className='fade-in'
            >
              <WarningIcon 
                sx={{
                  padding: '0',
                  color: orange,
                  margin: 'auto 0',
                }}
              />
              <Typography 
                sx={{
                  ...textStyle, 
                  fontStyle: 'italic',
                  margin: 'auto 0 auto 10px',
                  fontSize: `${fontSize - 3}px`,
                  textAlign: isTabletScreen && 'justify', 
                }}
              >
                Based on the delivery address, we've recommended a store to fulfill your order. 
                Ordering from places further away from you, or from multiple stores will increase the delivery fee. 
                We're here to help you save money.
              </Typography>
            </HMBox> 
            {selectedStoreId && (total - (selectedStoresObj[selectedStoreId].subtotal + deliveryTypeCost)) > 0 ? (
              <HMBox
                padding='0'
                width='100%'
                display='flex'
                className='fade-in' 
              >
                <Typography 
                  sx={{
                    ...textStyle, 
                    color: white,
                    margin: '0 auto',
                    borderRadius: '8px',
                    textAlign: 'center', 
                    padding: '2.5px 10px',
                    fontSize: `${fontSize}px`,
                    backgroundColor: darkBlue,
                  }}
                >
                  {`You save ${currency} ${(total - (selectedStoresObj[selectedStoreId].subtotal + deliveryTypeCost)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                </Typography>
              </HMBox> 
            ) : <></>}
            <HMBox
              width='100%'
              display='flex'
              margin='10px 0'
              overflowY='auto'
              flexDirection='column'
              className='HM-cart-info'
              minWidth={isTabletScreen && '80vw'}
              maxHeight={
                isVerySmallHeightScreen ? '40vh' 
                : isSmallHeightScreen ? '50vh' 
                : isNormalHeightScreen ? '60vh' 
                : isBigHeightScreen ? '70vh' 
                : '80vh'
              }
            >
              {Object.entries(selectedStoresObj).map(([key, data]) => (
                <HMStoreOption 
                  key={key}
                  store={data.store}
                  currency={currency}
                  value={data.store._id}
                  selectedStoreId={selectedStoreId}
                  recommendedStoreId={recommendedStoreId}
                  cost={data.subtotal + deliveryTypeCost}
                  handleStore={() => navegateToStorePage(data.store._id)}
                  handleRadioButtonChange={(e) => handleSelectedStoreChange(e)}
                  bgColor={recommendedStoreId === data.store._id ? lightGreen : seeThroughLightBlue}
                />
              ))}
            </HMBox>
          </>
        ) : <></>}
        <HMBox
          padding='0'
          width='100%'
          display='flex'
          margin='15px auto 0 auto'
          className='change-store-button fade-in'
        >
          <HMButton 
            type='button'
            width='100px'
            bgColor={gray}
            fontWeight={500}
            margin='auto 10px auto 0'
            handleClick={handleClose}
            text={<Typography sx={textStyle}>Cancel</Typography>}
          />
          <HMButton 
            type='button'
            width='100px'
            color={white}
            margin='auto 0 auto auto'
            bgColor={isDelete ? red : green} 
            disabled={
              (
                (isUpload && !prescriptionFileUrl.length) ||
                (isBuyFromSingleStore && !selectedStoreId) ||
                (isAddInfo && hasPrescription && !prescriptionFileUrl.length) ||
                ((isUpload || isAddInfo) && !cartDetails.find(cart => cart.cartItems.find(item => item.addInfoSelect))) 
              ) ? true : false}
            handleClick={
              isAddInfo ? handleAddInfo 
              : isDelete ? handleCartDeletion 
              : isUpload ? handlePrescription 
              : isBuyFromSingleStore && handleBuyFromSingleStore
            }
            text={
              <Typography sx={textStyle}>
                {isDelete ? 'Remove' : (isUpload || isBuyFromSingleStore || isAddInfo) && 'Submit'}
              </Typography>
            }
          />
        </HMBox>
      </HMModal> 
      <Section
        padding={10}
        margin='auto'
        className='empty-cart'
        bgColor={!isCartEmpty && white}
      >
        {!isCartEmpty && cartDetails.length ? (
          <Grid container spacing={2}>
            <Grid 
              item 
              xs={12} 
              sx={{display: 'flex', flexDirection: 'column'}}
              sm={isNormalTabletScreen || (isMediumScreen && visible) ? 12 : 8} 
            >
              <HMBox 
                width='100%'
                display='flex'
                flexDirection='row'
                className='fade-in'
                margin='5px 5px 5px 2.5px'
              >
                <HMCheckbox 
                  margin='auto 0'
                  handleChange={handleCheckAll} 
                  checked={!cartDetails.find(cart => cart.cartItems.find(item => !item.select))} 
                />
                <HMText
                  float='none'
                  text='Select all'
                  color={lightBlack}
                  width='max-content'
                  fontSize={`${fontSize}px`}
                  margin='auto auto auto 2.5px'
                />
                {(numberOfStores > 1) && Object.keys(selectedStoresObj).length ? (
                  <HMBox
                    padding='0'
                    margin='auto'        
                    display='flex'
                    className='fade-in'
                  >
                    <Tooltip 
                      arrow
                      placement='top' 
                      sx={{ margin: 'auto 0' }}
                      title='You can order from a single store insdead and spend less on your delivery fee' 
                    >
                      <HelpOutlineTwoToneIcon 
                        sx={{
                          cursor: 'pointer',
                          color: lightBlack,
                          fontSize: `${fontSize + 2}px`
                        }} 
                      />
                    </Tooltip> 
                    <HMButton 
                      type='button'
                      color={white}
                      fontWeight={500}
                      bgColor={orange}
                      padding='1px 5px'
                      margin='auto auto auto 5px'
                      paddingActive='0.5px 4.5px'
                      className='cart-prescription-button'
                      handleClick={handleBuyFromSingleStoreModal}
                      icon={
                        <StoreOutlinedIcon 
                          sx={{
                            ...iconStyle, 
                            marginRight: '5px',
                            fontSize: `${fontSize}px`, 
                          }} 
                        /> 
                      } 
                      text={
                        <Typography sx={{...textStyle, fontSize: `${fontSize - 2}px`}}>
                          Buy from a single store
                        </Typography>
                      }
                    />
                  </HMBox>
                ) : <></>}
                <HMButton 
                  type='button'
                  fontWeight={500}
                  padding='1px 5px'
                  margin='auto 0 auto auto'
                  paddingActive='0.5px 4.5px'
                  className='cart-prescription-button'
                  handleClick={handleCartDeletionModal}
                  disabled={isAnyItemSelected ? false : true}
                  bgColor={isAnyItemSelected ? red : 'inherit'}
                  color={isAnyItemSelected ? white : lightBlack}
                  icon={
                    <DeleteIcon 
                      sx={{
                        ...iconStyle, 
                        marginRight: '5px',
                        fontSize: `${fontSize}px`, 
                      }} 
                    /> 
                  } 
                  text={
                    <Typography sx={{...textStyle, fontSize: `${fontSize - 2}px`}}> 
                      {cartDetails.find(cart => cart.cartItems.find(item => item.select === true)) ? 'Remove selected' : 'Select item'}
                    </Typography>
                  }
                />
              </HMBox>
              {cartDetails.map((cart, index) => (
                <HMStoreData 
                  key={index}
                  isCart={true}
                  storeData={cart}
                  isLoading={isLoading}
                  updatingListing={updatingListing}
                  handleAddInfoModal={(item) => handleAddInfoModal(item)}
                  navegateToStorePage={() => navegateToStorePage(cart.store)}
                  isStoreChecked={!cart.cartItems.find(item => !item.select)}
                  handleQuantityReduce={(item) => handleQuantityReduce(item)}
                  navegateToProductPage={(item) => navegateToProductPage(item)}
                  handleQuantityIncrease={(item) => handleQuantityIncrease(item)}
                  handlePrescriptionModal={(item) => handlePrescriptionModal(item)}
                  handleQuantityChange={(e, item) => handleQuantityChange(e, item)}
                  handleStoreCheckboxChange={(e) => handleStoreCheckboxChange(e, cart.store)}
                  handleItemCheckboxChange={(e, listingId) => handleItemCheckboxChange(e, listingId)}
                />
              ))}
            </Grid>
            <Grid item xs={12} sm={isNormalTabletScreen || (isMediumScreen && visible) ? 12 : 4}>
              <HMOrderComputation 
                tax={tax}
                total={total}
                subtotal={subtotal}
                deliveryType={deliveryType}
                deliveryCost={deliveryCost}
                numberOfStores={numberOfStores}
                actionMessage='Proceed to checkout'
                handleProceed={handleProceedToCheckout}
                handleNormalDelivery={handleNormalDelivery}
                handleUrgentDelivery={handleUrgentDelivery}
                isDisabled={!checkoutData.ready || isLoading}
                margin={
                  isNormalTabletScreen || (isMediumScreen && visible) ? 'auto' 
                  : orderSummaryTop > 50 ? `${orderSummaryTop}px auto auto auto`
                  : 'auto' 
                }
              />
            </Grid>
          </Grid>
        ) : isCartEmpty && !isLoading && (!cartData.length || !cartDetails.length) ? (
          <EmptyCart 
            handleBack={() => navigate(-1)}
            handleAddProducts={() => navigate('/categories')}
          />
        ) : <></>}
      </Section>
    </Page>
  ) : (
    <Section
      padding={10}
      margin='auto'
    >
      <HMLoginFirst 
        message='You need to login first before accessing your cart'
      />
    </Section>
  )
}

export default Cart