import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { toast } from 'react-toastify'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Radio from '@mui/material/Radio'
import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import { 
  getMe, 
  resetMe,
  updateMe, 
} from '../../features/me/meSlice'
import { 
  resetCart,
  updateCart, 
} from '../../features/cart/cartSlice'
import { 
  resetOrder,
  createOrder, 
} from '../../features/orders/orderSlice'
import { 
  getCheckout,
  resetCheckout,
  deleteCheckout,
} from '../../features/checkout/checkoutSlice'
import { 
  getAllInsuredProducts,
  getInsurerWithProducts,
  resetRelationProductsInsurers
} from '../../features/relationProductsInsurers/relationProductsInsurersSlice'
import CartListings from '../../components/cart/CartListings'
import AddressDetails from '../../components/checkout/AddressDetails'
import HMInsuranceCard from '../../components/checkout/HMInsuranceCard'
import EditDeliveryInfo from '../../components/checkout/EditDeliveryInfo'
import HMInsuranceCardForm from '../../components/checkout/HMInsuranceCardForm'
import PaymentMethodDetails from '../../components/checkout/PaymentMethodDetails'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMOrderComputation from '../../components/common/layout/HMOrderComputation'
import HMPaymentMethods from '../../components/common/layout/HMPaymentMethods'
import HMPaymentOptions from '../../components/common/layout/HMPaymentOptions'
import HMViewAddedInfo from '../../components/common/layout/HMViewAddedInfo'
import HMPaymentOption from '../../components/common/layout/HMPaymentOption'
import HMPaymentMethod from '../../components/common/layout/HMPaymentMethod'
import HMLoginFirst from '../../components/common/layout/HMLoginFirst'
import HMAccordion from '../../components/common/layout/HMAccordion'
import HMModal from '../../components/common/layout/HMModal'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMToogleButton from '../../components/common/tools/HMToogleButton'
import HMButton from '../../components/common/tools/HMButton'
import HMSpiner from '../../components/common/tools/HMSpiner'
import HMAlert from '../../components/common/tools/HMAlert'
import HMText from '../../components/common/tools/HMText'
import useWindowDimensions from '../../hooks/useWindowDimensions' 
import {
  currencies,
  orderStatus,
  storeDelivery,
  validatePhoneNumber,
  paymentMethodDetails,
  excludeKeysFromObject,
} from '../../hooks/helperFunctions'
import {
  fontSize,
  iconStyle,
  textStyle,
  deleteTextStyle,
} from '../../components/common/tools/Styles'
import {
  red,
  gray,
  blue,
  white,
  green,
  orange,
  lightRed,
  darkBlue,
  lightBlue,
  lightBlack,
  lightGreen,
  lightWarning,
  getRandomColor,
  superLightBlue,
  skeletonsLightBlue,
  seeThroughLightBlue,
} from '../../hooks/useColors'

const findInsuredItemsData = (checkoutDetails, insuredProducts) => {
  const insuredItemsObj = {}
  let supportedInsurers = []
  let supportedInsurersObj = {}
  const insuredProductsObj = {}
  for (let data of  insuredProducts) {
    if (data.product) {
      insuredProductsObj[data.product._id] = data.insurers
    }
  }
  for (let checkout of checkoutDetails) {
    for (let item of checkout.checkoutItems) {
      if (
          'addedInfo' in item && 
          insuredProductsObj[item.product] &&
          Object.keys(item['addedInfo']).length &&
          item['addedInfo'].uploadedPrescription && 
          (item.classification.includes('prescription') || item.classification.includes('otc'))
        ) {
        insuredItemsObj[item.product] = insuredItemsObj[item.product] ? [...insuredItemsObj[item.product], {
          ...item,
          store: checkout.store
        }] : [{
          ...item,
          store: checkout.store
        }]
        supportedInsurers = supportedInsurers.concat(insuredProductsObj[item.product])
      }
    }
  }

  for (let insurer of supportedInsurers) {
    supportedInsurersObj[insurer._id] = insurer
  }

  supportedInsurers = []

  for (let key in supportedInsurersObj) {
    supportedInsurers.push(supportedInsurersObj[key])
  }

  return {
    insuredItemsObj,
    supportedInsurers,
    supportedInsurersObj
  }
}
const computationCheckoutData = (checkoutDetails) => {
  let total = 0 
  let subtotal = 0
  let deliveryCost = 0
  let numberOfStores = 0 
  for (let checkout of checkoutDetails) {
    numberOfStores++
    deliveryCost += checkout.deliveryOption.cost
    for (let item of checkout.checkoutItems) {
        const cost = (item.quantity * item.onlinePrice) 
        subtotal += cost
        total += cost 
    }
  }
  total += deliveryCost
  return {
    total,
    subtotal,
    deliveryCost,
    numberOfStores
  }
}

const paymentDummyData = [
  {
    value: '4544 04** **** 0145',
    method: 'Credit/Debit Card',
  }, {
    value: '4544 04** **** 0144',
    method: 'Credit/Debit Card',
  }, {
    value: '+250 788 888 888',
    method: 'MTN Mobile Money',
  }, {
    value: '+250 722 222 222',
    method: 'Airtel Mobile Money',
  }
]
const cashInfoSekeleton = {
  value: 'cash',
  method: 'Cash On Delivery',
}

function Checkout() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { 
    me, 
    isOne, 
    message, 
    isError, 
    isUpdated, 
    isLoading,
  } = useSelector((state) => state.me)
  const { 
    visible,
  } = useSelector((state) => state.nav)
  const { 
    user 
  } = useSelector((state) => state.auth)
  const cartState = useSelector((state) => state.cart)
  const orderState = useSelector((state) => state.order)
  const checkoutState = useSelector((state) => state.checkout)
  const { 
    checkout,
  } = checkoutState
  const {
    insuredProducts,
    insurerProducts,
    isInsurerProducts,
    areInsuredProducts
  } = useSelector(state => state.relationProductsInsurers)

  const deliveryInfoSekeleton = {
    phone: '',
    mapAddress: '',
    default: false,
    category: 'Home',
    recipientName: '',
    coordinates: {
      lng: null,
      lat: null,
    }
  }
  const [cardImageUrl, setCardImageUrl] = useState('')
  const insuranceInfoSekeleton = {
    save: true,
    insurer: '',
    default: false,
    nameOnCard: '',
    cardNumber: '',
    cardImage: cardImageUrl,
  }

  const tax = 0
  // const [tax, setTax] = useState(0)
  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [currency, setCurrency] = useState('')
  const [useInsurer, setUseInsurer] = useState(false)
  const [createdOrder, setCreatedOrder] = useState({})
  const [wrongNumber, setWrongNumber] = useState(false)
  const [chosenInsurer, setChosenInsurer] = useState({})
  const [isAddAddress, setIsAddAddress] = useState(false)
  const [isAddInsurer, setIsAddInsurer] = useState(false)
  const [isEditInsurer, setIsEditInsurer] = useState(false)
  const [orderSummaryTop, setOrderSummaryTop] = useState(0)
  const [isEditAddress, setIsEditAddress] = useState(false)
  const [checkoutDetails, setCheckoutDetails] = useState([])
  const [currentStoreId, setCurrentStoreId] = useState(null)
  const [specificInsurer, setSpecificInsurer] = useState(null)
  const [isDeleteAddress, setIsDeleteAddress] = useState(false)
  const [isSelectInsurer, setIsSelectInsurer] = useState(false)
  const [isSelectAddress, setIsSelectAddress] = useState(false)
  const [isViewAddedInfo, setIsViewAddedInfo] = useState(false)
  const [qualifiedInsurers, setQualifiedInsurers] = useState([])
  const [currentAddedInfo, setCurrentAddedInfo] = useState(null)
  const [chosenAddressInfo, setChosenAddressInfo] = useState({})
  const [checkoutComputation, setCheckoutComputation] = useState({})
  const [chosenInsuranceInfo, setChosenInsuranceInfo] = useState({})
  const [isAddPaymentMethod, setIsAddPaymentMethod] = useState(false)
  const [qualifiedInsurersObj, setQualifiedInsurersObj] = useState({})
  const [currentAddressIndex, setCurrentAddressIndex] = useState(null)
  const [isStoreDeliveryType, setIsStoreDeliveryType] = useState(false)
  const [isAddSpecificInsurer, setIsAddSpecificInsurer] = useState(false)
  const [deliveryInfo, setDeliveryInfo] = useState(deliveryInfoSekeleton)
  const [currentInsuranceIndex, setCurrentInsuranceIndex] = useState(null)
  const [specificPaymentMethod, setSpecificPaymentMethod] = useState(null)
  const [isSelectPaymentMethod, setIsSelectPaymentMethod] = useState(false)
  const [insuredCheckoutItemsObj, setInsuredCheckoutItemsObj] = useState({})
  const [insurerFormData, setInsurerFormData] = useState(insuranceInfoSekeleton)
  const [selectedStoreDeliveryType, setSelectedStoreDeliveryType] = useState(null)
  const [selectedPaymentInfo, setSelectedPaymentInfo] = useState(cashInfoSekeleton)
  const [isAddSpecificPaymentMethod, setIsAddSpecificPaymentMethod] = useState(false)
  const [confirmedPaymentInfo, setConfirmedPaymentInfo] = useState(cashInfoSekeleton)
  const [itemsSupportedByChosenInsurer, setItemsSupportedByChosenInsurer] = useState([])
  const [airtelFormData, setAirtelFormData] = useState({
    name: '',
    save: true,
    airtelNumber: '',
  })
  const [cardFormData, setCardFormData] = useState({
    cvv: '',
    cardNumber: '',
    nameOnCard: '',
    save: true,
    cardExpirationDate: '',
  })
  const [mtnFormData, setMtnFormData] = useState({
    name: '',
    save: true,
    mtnNumber: '',
    isValid: false
  })

  const {
    windowW,
    windowH,
  } = useWindowDimensions()

  const isTabletScreen = windowW < 600 ? true : false
  const isMediumScreen = windowW < 1010 ? true : false
  const isNormalTabletScreen = windowW < 765 ? true : false
  const isBigHeightScreen = windowH < 1000 ? true : false
  const isNormalHeightScreen = windowH < 650 ? true : false
  const isSmallHeightScreen = windowH < 400 ? true : false
  const isVerySmallHeightScreen = windowH < 300 ? true : false

  const breadOptions = [
    { text: 'Home', link: '/landing' },
    { text: 'Checkout', link: null }
  ]

  useEffect(() => {
    if (user && Object.keys(user).length) {
      dispatch(getMe())
      dispatch(getAllInsuredProducts())
    } 
    setCurrency(currencies.find(curr => curr.country === 'Rwanda').currency)
    // eslint-disable-next-line
  }, [user])

  useEffect(() => {
    if (orderState.isOrderCreated) {
      setCreatedOrder(orderState.myOrder) 
      dispatch(resetOrder())

      const listingIdsObj = {}
      for (let checkout of checkoutDetails) {
        for (let item of checkout.checkoutItems) {
          listingIdsObj[item.listing] = 1
        } 
      }

      if (!checkout.isFastBuy) {
        const cartItems = []
        cartState.cart.cartItems.forEach(item => {
          const listingId = item.storeListing._id
          if (!(listingId in listingIdsObj)) {
            const cartItem = {
              quantity: item.quantity,
              storeListing: listingId
            }
            if ('addedInfo' in item) {
              cartItem['addedInfo'] = item.storeListing.addedInfo
            }
            cartItems.push(cartItem)
          }
        })
        
        dispatch(updateCart({cartItems}))
      } else if (checkout.isFastBuy) {
        dispatch(deleteCheckout())
      }
    }
    
    if (cartState.isUpdated) {
      dispatch(resetCart())
      dispatch(deleteCheckout())
    }

    if (isUpdated || isOne || isError) {
      dispatch(resetMe())
    } 

    if (isError) {
      toast.error(message) 
    } else if (isOne || isUpdated) {
      if (me.deliveryInfo && me.deliveryInfo.length) {
        if (me.deliveryInfo.length === 1) {
          setChosenAddressInfo(me.deliveryInfo[0])
        } else {
          setChosenAddressInfo(me.deliveryInfo.find(data => data.default))
        } 
      } else {
        setChosenAddressInfo({})
      }
      
      if (me.insuranceInfo && me.insuranceInfo.length) {
        if (me.insuranceInfo.length === 1) {
          if (me.insuranceInfo[0].insurer) {
            setChosenInsuranceInfo(me.insuranceInfo[0])
          }
        } else {
          let tempInsuranceInfo = {} 
          for (let data of me.insuranceInfo) {
            if (data.default && data.insurer) {
              tempInsuranceInfo = { ...data }
              break
            } 
          }
          
          setChosenInsuranceInfo(Object.keys(tempInsuranceInfo).length ? tempInsuranceInfo : me.insuranceInfo[0])
        }
      } else {
        setChosenInsuranceInfo({})
      }
    } 

    if (checkoutState.isOne) {
      if (checkout === null) {
        navigate('/cart')
      }
      dispatch(resetCheckout())
    } else if (checkoutState.isDeleted) {
      dispatch(resetCheckout())
      if (Object.keys(createdOrder).length) {
        navigate(`/myorders/${createdOrder._id.toString()}`)
      }
    }
    // eslint-disable-next-line
  }, [isOne, isError, isUpdated, cartState, orderState, checkoutState])

  useEffect(() => {
    if (checkout !== null && Object.keys(checkout).length) {
      const tempCheckoutDetails = checkout.checkoutDetails

      setCheckoutDetails(tempCheckoutDetails)
      setCheckoutComputation(computationCheckoutData(tempCheckoutDetails))

      const {
        insuredItemsObj,
        supportedInsurers,
        supportedInsurersObj
      } = findInsuredItemsData(tempCheckoutDetails, insuredProducts) 

      setQualifiedInsurers(supportedInsurers)
      setInsuredCheckoutItemsObj(insuredItemsObj)
      setQualifiedInsurersObj(supportedInsurersObj)
    } else {
      dispatch(getCheckout())
    } 
    // eslint-disable-next-line
  }, [checkout, insuredProducts])

  useEffect(() => {
    if (Object.keys(chosenInsuranceInfo).length && Object.keys(qualifiedInsurersObj).length) {
      const tempChosenInsurer = qualifiedInsurersObj[chosenInsuranceInfo.insurer._id]
      if (tempChosenInsurer) {
        setChosenInsurer(tempChosenInsurer)
        dispatch(getInsurerWithProducts(tempChosenInsurer._id))
      }
    }
    // eslint-disable-next-line
  }, [qualifiedInsurersObj, chosenInsuranceInfo])

  useEffect(() => {
    if (areInsuredProducts || isInsurerProducts) {
      dispatch(resetRelationProductsInsurers())
    }
    // eslint-disable-next-line
  }, [isInsurerProducts, areInsuredProducts])

  useEffect(() => {
    if (
      Object.keys(chosenInsurer).length && 
      Object.keys(insurerProducts).length &&
      Object.keys(insuredCheckoutItemsObj).length
    ) {
      let insuredItems = []
      insurerProducts.products.forEach(product => {
        if (insuredCheckoutItemsObj[product._id]) {
          insuredItems = insuredItems.concat(insuredCheckoutItemsObj[product._id])
        }
      })
      setItemsSupportedByChosenInsurer(insuredItems)
    }
  }, [insurerProducts, chosenInsurer, insuredCheckoutItemsObj])

  useEffect(() => {
    setInsurerFormData((prevState) => ({
      ...prevState,
      cardImage: cardImageUrl
    }))
  }, [cardImageUrl])

  const navegateToProductPage = (item) => {
    navigate(`/categories/${item.categoryParentId}/${item.categoryId}/${item.product}`)
  }
  const navegateToStorePage = (storeId) => {
    navigate(`/stores/${storeId}`)
  }

  const handleEditAddressModal = (e, addressInfo, addressIndex) => {
    e.stopPropagation()
    setOpen(true)
    setIsEditAddress(true)
    setIsSelectAddress(false)
    setDeliveryInfo(addressInfo)
    setTitle('Edit Delivery Address')
    setCurrentAddressIndex(addressIndex)
  }
  const handleEditAddressSave = () => {
    const updatedDeliveryInfo = me.deliveryInfo.map((data, index) => {
      if (index === currentAddressIndex) {
        return deliveryInfo
      } else {
        if (deliveryInfo.default) {
          return {
            ...data,
            default: false
          }
        } else {
          return data
        }
      }
    })
    const updatedMe = {
      deliveryInfo: updatedDeliveryInfo
    }
    dispatch(updateMe(updatedMe))
    handleSelectAddressModal()
  }

  const handleDeleteAddressModal = (e, addressInfo, addressIndex) => {
    e.stopPropagation()
    setOpen(true)
    setIsDeleteAddress(true)
    setIsSelectAddress(false)
    setDeliveryInfo(addressInfo)
    setTitle('Delete Delivery Address')
    setCurrentAddressIndex(addressIndex)
  }
  const handleDeleteAddress = () => {
    const updatedDeliveryInfo = me.deliveryInfo.filter(addressInfo => addressInfo._id !== deliveryInfo._id)

    if (deliveryInfo.default && updatedDeliveryInfo.length) {
      updatedDeliveryInfo[0] = {
        ...updatedDeliveryInfo[0],
        default: true
      }
    }
    
    const updatedMe = {
      deliveryInfo: updatedDeliveryInfo
    }

    dispatch(updateMe(updatedMe))

    if (updatedDeliveryInfo.length) {
      handleSelectAddressModal()
    } else {
      handleClose()
    }
  }

  const handleSelectAddressModal = () => {
    setOpen(true)
    setIsEditAddress(false)
    setIsSelectAddress(true)
    setTitle('Select Delivery Address')
    setDeliveryInfo(deliveryInfoSekeleton)
  }
  const handleAddAddressModal = () => {
    setOpen(true)
    setIsAddAddress(true)
    setIsEditAddress(false)
    setIsSelectAddress(false)
    setTitle('Add Delivery Address')
    setDeliveryInfo(deliveryInfoSekeleton)
  }
  const handleAddAddressSave = () => {
    let updatedDeliveryInfo = []
    let isNewAddressInfo = false
    if (me.deliveryInfo && me.deliveryInfo.length) {
      if (deliveryInfo.default) {
        updatedDeliveryInfo = me.deliveryInfo.map(data => ({
          ...data,
          default: false,
        }))
      } else {
        updatedDeliveryInfo = me.deliveryInfo.map(data => data)
      }
      isNewAddressInfo = false
      updatedDeliveryInfo.push(deliveryInfo)
    } else {
      isNewAddressInfo = true
      updatedDeliveryInfo.push({
        ...deliveryInfo,
        default: true,
      })
    }

    const updatedMe = {
      deliveryInfo: updatedDeliveryInfo
    }
    dispatch(updateMe(updatedMe))
    
    if (isNewAddressInfo) {
      handleClose()
    } else {
      handleSelectAddressModal()
    }
  }

  const handleChooseAddress = (addressInfo) => {
    setChosenAddressInfo(addressInfo)
    handleClose()
  }

  const handleChangeStoreDeliveryTypeModal = (storeId) => {
    setOpen(true)
    setCurrentStoreId(storeId)
    setIsStoreDeliveryType(true)
    setTitle('Choose your delivery option')
    setSelectedStoreDeliveryType(checkoutDetails.find(checkout => checkout.store === storeId).deliveryOption.type)
  }
  const boxHandleSelectStoreDeliveryType = (deliveryType) => {
    setSelectedStoreDeliveryType(deliveryType)
  }
  const handleSelectStoreDeliveryType = (e) => {
    e.stopPropagation()
    setSelectedStoreDeliveryType(e.target.value)
  }
  const handleChangeStoreDeliveryType = () => {
    const tempDeliveryOption = storeDelivery.find(data => data.type === selectedStoreDeliveryType)
    const tempCheckoutDetails = checkoutDetails.map(checkout => {
      if (checkout.store === currentStoreId) {
        return {
          ...checkout,
          deliveryOption: tempDeliveryOption,
          subtotal: checkout.subtotal - checkout.deliveryOption.cost + tempDeliveryOption.cost
        }
      } else {
        return checkout
      }
    })
    setCheckoutDetails(tempCheckoutDetails)
    setCheckoutComputation(computationCheckoutData(tempCheckoutDetails))
    handleClose()
  }
 
  const handleSelectPaymentInfoModal = () => {
    setOpen(true)
    setIsAddPaymentMethod(false)
    setIsSelectPaymentMethod(true)
    setTitle('Select Payment Method')
    // setDeliveryInfo(deliveryInfoSekeleton)
  }
  const handleAddPaymentMethodModal = () => {
    setOpen(true)
    setIsAddPaymentMethod(true)
    setTitle('Add Payment Method')
    setIsSelectPaymentMethod(false)
    // setDeliveryInfo(deliveryInfoSekeleton)
  }
  const handleAddPaymentMethodConfirm = () => {
    setConfirmedPaymentInfo(selectedPaymentInfo)
    // setConfirmedPaymentInfo(cashInfoSekeleton)
    handleClose()
  }
  const handleAddSpecificPaymentMethodModal = (type) => {
    const tempPaymentMethod = paymentMethodDetails.find(item => item.type === type)
    setIsAddPaymentMethod(false)
    setTitle(tempPaymentMethod.title)
    setIsAddSpecificPaymentMethod(true)
    setSpecificPaymentMethod(tempPaymentMethod)
  }

  const handleSetPaymentInfo = (info) => {
    setSelectedPaymentInfo(info)
  }
  const handleSelectPaymentInfo = (e) => {
    e.stopPropagation()
    let tempPayInfo = null
    const value = e.target.value
    paymentDummyData.forEach(item => {
      if (item.value === value) {
        tempPayInfo = item
      }
    })
    
    if (tempPayInfo) {
      setSelectedPaymentInfo(tempPayInfo)
    } else {
      setSelectedPaymentInfo({
        value: 'cash',
        method: 'Cash On Delivery'
      })
    }
  }

  const handleAddInsurerModal = () => {
    setOpen(true)
    setIsAddInsurer(true)
    setIsEditInsurer(false)
    setIsSelectInsurer(false)
    setIsAddSpecificInsurer(false)
    setTitle('Add Insurance Information')
  }
  const handleEditInsurerModal = (e, insuranceData, insurer, insuranceIndex) => {
    e.stopPropagation()
    setOpen(true)
    setIsEditInsurer(true)
    setIsAddInsurer(false)
    setIsSelectInsurer(false)
    setIsAddSpecificInsurer(false)
    setTitle(`Edit - ${insurer.name}`)
    setInsurerFormData({
      ...insuranceData,
      save: insurerFormData.save,
    })
    setCardImageUrl(insuranceData.cardImage)
    setCurrentInsuranceIndex(insuranceIndex)
  }
  const handleEditInsurerConfirm = () => {
    let updatedInsuranceData = {
      default: insurerFormData.default,
      cardImage: insurerFormData.cardImage,
      cardNumber: insurerFormData.cardNumber,
      nameOnCard: insurerFormData.nameOnCard,
    }
    const updatedInsuranceInfo = me.insuranceInfo.map((data, index) => {
      if (index === currentInsuranceIndex) {
        updatedInsuranceData = {
          ...updatedInsuranceData,
          insurer: data.insurer,
        }
        return updatedInsuranceData
      } else {
        if (insurerFormData.default) {
          return {
            ...data,
            default: false
          }
        } else {
          return data
        }
      }
    })
    const updatedMe = {
      insuranceInfo: updatedInsuranceInfo
    }
    dispatch(updateMe(updatedMe))
    
    setChosenInsuranceInfo(updatedInsuranceData)
    
    handleClose()
  }
  const handleAddInsurerConfirm = () => {
    let updatedInsuranceInfo = []
    const newInsuranceData = {
      insurer: specificInsurer._id,
      default: insurerFormData.default,
      cardImage: insurerFormData.cardImage,
      cardNumber: insurerFormData.cardNumber,
      nameOnCard: insurerFormData.nameOnCard,
    }
    if (me.insuranceInfo && me.insuranceInfo.length) {
      if (newInsuranceData.default) {
        updatedInsuranceInfo = me.insuranceInfo.map(data => ({
          ...data,
          default: false,
        }))
      } else {
        updatedInsuranceInfo = me.insuranceInfo.map(data => data)
      }
    } 
    updatedInsuranceInfo.push(newInsuranceData)

    const updatedMe = {
      insuranceInfo: updatedInsuranceInfo
    }

    if (insurerFormData.save) {
      dispatch(updateMe(updatedMe))
    }

    setChosenInsuranceInfo({
      ...newInsuranceData,
      insurer: specificInsurer
    })
    
    handleClose()
  }
  const handleAddSpecificInsurerModal = (insurer) => {
    setOpen(true)
    setTitle(insurer.name)
    setIsAddInsurer(false)
    setSpecificInsurer(insurer)
    setIsAddSpecificInsurer(true)
    setInsurerFormData(insuranceInfoSekeleton)
  }
  const handleSelectInsurerModal = () => {
    setOpen(true)
    setIsAddInsurer(false)
    setIsEditInsurer(false)
    setIsSelectInsurer(true)
    setTitle('Select Insurance Card')
  }
  const handleSelectInsuranceInfo = (insuranceData) => {
    setChosenInsuranceInfo(insuranceData)
    handleClose()
  }

  const handleViewAddedInfoModal = (addedInfo) => {
    setOpen(true)
    setIsViewAddedInfo(true)
    setCurrentAddedInfo(addedInfo)
    setTitle('Additional Information')
  }

  const handleViewPrescription = (prescription) => {
    window.open(prescription, '_blank', 'noopener,noreferrer')
  }

  const handleClose = () => {
    setTitle('')
    setOpen(false)
    setCardImageUrl('')
    setIsAddInsurer(false)
    setIsAddAddress(false)
    setIsEditInsurer(false)
    setIsEditAddress(false)
    setIsDeleteAddress(false)
    setIsViewAddedInfo(false)
    setIsSelectInsurer(false)
    setIsSelectAddress(false)
    setIsAddPaymentMethod(false)
    setIsStoreDeliveryType(false)
    setIsAddSpecificInsurer(false)
    setIsSelectPaymentMethod(false)
    setIsAddSpecificPaymentMethod(false)
  }

  const handleChangeMapAddress = (address) => {
    setDeliveryInfo((prevState) => ({
      ...prevState,
      mapAddress: address
    }))
  }

  const handleSelectMapAddress = async (address) => {
    const results = await geocodeByAddress(address)
    const ll = await getLatLng(results[0])

    setDeliveryInfo((prevState) => ({
      ...prevState,
      coordinates: ll,
      mapAddress: address,
    }))
  }

  const handleChange = (e) => {
    let value = e.target.value
    const name = e.target.name

    if (name === 'phone' && validatePhoneNumber(value) !== null) {
      const {
        isValid,
        phoneNumber
      } = validatePhoneNumber(value)

      if (!isValid && phoneNumber.length) {
        e.target.classList.add('wrong-input')
        setWrongNumber(true)
      } else if (isValid || !phoneNumber.length) {
        e.target.classList.remove('wrong-input')
        setWrongNumber(false)
      } 

      setDeliveryInfo((prevState) => ({
        ...prevState,
        [name]: phoneNumber,
      }))
    } 
    
    if (name !== 'phone') {
      setDeliveryInfo((prevState) => ({
          ...prevState,
          [name]: value,
      }))
    }
  }
  const handleInsurerDataChange = (e) => {
    const value = e.target.value
    const name = e.target.name

    setInsurerFormData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }
  const handleCardDataChange = (e) => {
    const value = e.target.value
    const name = e.target.name

    setCardFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }
  const handleMtnDataChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    if (name === 'mtnNumber' && validatePhoneNumber(value) !== null) {
      const {
        isValid,
        phoneNumber
      } = validatePhoneNumber(value)
      
      setMtnFormData((prevState) => ({
        ...prevState,
        isValid,
        mtnNumber: phoneNumber,
      }))
    }  
    
    if (name !== 'mtnNumber') {
      setMtnFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }

  }
  const handleAirtelDataChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    if (name === 'airtelNumber' && validatePhoneNumber(value) !== null) {
      const {
        isValid,
        phoneNumber
      } = validatePhoneNumber(value)
      
      setMtnFormData((prevState) => ({
        ...prevState,
        isValid,
        airtelNumber: phoneNumber,
      }))
    }  
    
    if (name !== 'airtelNumber') {
      setAirtelFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }
  const handleSavePaymentMethod = () => {
    if (specificPaymentMethod.type === 'card') {
      setCardFormData((prevState) => ({
        ...prevState,
        save: !cardFormData.save,
      }))
    } else if (specificPaymentMethod.type === 'mtn') {
      setMtnFormData((prevState) => ({
        ...prevState,
        save: !mtnFormData.save,
      }))
    } else if (specificPaymentMethod.type === 'airtel') {
      setAirtelFormData((prevState) => ({
        ...prevState,
        save: !airtelFormData.save,
      }))
    }
  }

  const handleSaveInsurer = () => {
    setInsurerFormData((prevState) => ({
      ...prevState,
      save: !insurerFormData.save,
    }))
  }
 
  const handleProceedToPlaceOrder = () => {
    const newOrder = {
      paymentInfo: {
        paid: false,
        method: useInsurer ? paymentMethodDetails.find(m => m.type === 'insurance').title : selectedPaymentInfo.method,
      },
      deliveryInfo: chosenAddressInfo,
      orderTotal: checkoutComputation.total,
      orderDetails: checkoutDetails.map(checkout => ({
        ...excludeKeysFromObject(checkout, ['checkoutItems']),
        select: false,
        status: orderStatus[0],
        approvedInsurance: false,
        orderItems: checkout.checkoutItems.map(item => ({
          ...excludeKeysFromObject(item, ['addInfoSelect', 'select', 'stockCount', 'transitStockCount']),
          usedInsurance: (useInsurer && itemsSupportedByChosenInsurer.find(insuredItem => insuredItem.listing === item.listing)) ? true : false,
        }))
      }))
    }
    if (useInsurer && itemsSupportedByChosenInsurer.length) {
      const storeValidation = {}
      
      itemsSupportedByChosenInsurer.forEach(item => {
        const storeId = item.store
        storeValidation[storeId] = {
          state: '',
          reason: '',
          paid: false,
          coverage: 0,
        }
      })
      
      newOrder['insuranceInfo'] = {
        ...chosenInsuranceInfo,
        storeValidation,
        insurer: {
          id: chosenInsuranceInfo.insurer._id,
          name: chosenInsuranceInfo.insurer.name,
          logo: chosenInsuranceInfo.insurer.logo,
          type: chosenInsuranceInfo.insurer.type,
          origin: chosenInsuranceInfo.insurer.origin,
        }
      }
    }

    dispatch(createOrder(newOrder))
  }

  const disabledDilivery = (
      deliveryInfo.category === '' ||
      deliveryInfo.mapAddress === '' ||
      deliveryInfo.recipientName === '' ||
      wrongNumber === true 
    ) ? true : false
  const disabledPaymentMethod = (
      false
    ) ? true : false
  const isPlaceOrderDisabled = (
      !Object.keys(chosenAddressInfo).length ||
      confirmedPaymentInfo.value !== 'cash' || // TO DO : Remove once other payment oprions are working
      (useInsurer && !(Object.keys(insuredCheckoutItemsObj).length && Object.keys(chosenInsuranceInfo).length))
    ) ? true : false

  const scrollListener = () => {
    setOrderSummaryTop(window.scrollY)
  }
  
  window.addEventListener('scroll', scrollListener)

  return (
    <Page className='HM-checkout'>
      { (cartState.isLoading || checkoutState.isLoading || orderState.isLoading || isLoading) ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer
        display='flex'
      >
        <HMBreadcrumbs 
          options={breadOptions}
          margin='auto auto auto 0'
        />
        <HMBox 
          padding='0'
          float='right'
          display='flex'
        >
          <HMButton 
            type='button'
            bgColor={orange}
            isResponsive={true}
            margin='auto 0 auto auto'
            handleClick={() => navigate(-1)}
            text={<Typography sx={textStyle}>Back</Typography>} 
            icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
          />
        </HMBox>
      </FullWidthContainer>
      <HMModal 
        open={open} 
        title={title}
        maxHeight='95vh'
        handleClose={handleClose} 
        width={isTabletScreen ? '90vw' : '550px'}
        maxWidth={isTabletScreen ? '95vw' : '600px'}
      >
        {isLoading ? (
          <HMBox
            width='100%'
            height='50vh'
            display='flex'
            minWidth={isTabletScreen ? '70vw' : '500px'}
          >
            <HMSpiner 
              size={50}
              margin='auto'
              bgColor='inherit'
            />
          </HMBox>
        ) : isSelectAddress ? (
          <HMBox
            width='100%'
            display='flex' 
            overflowY='auto'
            color={darkBlue}
            flexDirection='column'
            minWidth={!isTabletScreen && '500px'}
            maxHeight={
              isVerySmallHeightScreen ? '50vh' 
              : isSmallHeightScreen ? '60vh' 
              : isNormalHeightScreen ? '70vh' 
              : isBigHeightScreen ? '80vh' 
              : '80vh'
            }
          >
            <HMButton 
              width='100%'
              type='button'
              color={blue}
              padding='20px'
              bgColor='inherit'
              borderRadius={10}
              paddingActive='20px'
              margin='10px auto 15px auto'
              border={`1px solid ${blue}`}
              handleClick={handleAddAddressModal}
              icon={<AddIcon sx={{...iconStyle, margin: 'auto 0 auto auto'}} />} 
              text={
                <Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>
                  Add delivery address
                </Typography>
              } 
            />
            {me.deliveryInfo.map((addressInfo, index) => (
              <AddressDetails 
                key={index}
                margin='5px 0'
                isColumn={true}
                canDelete={true}
                activeCursor={true}
                bgColor={lightBlue}
                addressInfo={addressInfo}
                hoverBgColor={lightGreen}
                isDefault={addressInfo.default}
                isTabletScreen={isTabletScreen}
                handleChooseClick={() => handleChooseAddress(addressInfo)}
                handleChangeClick={(e) => handleEditAddressModal(e, addressInfo, index)}
                handleDeleteClick={(e) => handleDeleteAddressModal(e, addressInfo, index)}
              />
            ))}
          </HMBox>
        ) : (isAddAddress || isEditAddress) ? (
          <HMBox
            padding='0'
            width='100%'
            minWidth={!isTabletScreen && '500px'}
          > 
            <HMBox
              padding='0'
              margin='15px 0'
              className='fade-in'
              width={isTabletScreen ? '80vw' : '100%'}
            >
              <EditDeliveryInfo 
                handleChange={handleChange}
                deliveryInfoItem={deliveryInfo}
                isTabletScreen={isTabletScreen}
                handleChangeMapAddress={handleChangeMapAddress}
                handleSelectMapAddress={handleSelectMapAddress}
              />
            </HMBox> 
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='15px auto 0 auto'
              className='change-store-button fade-in'
            >
              <HMButton 
                type='button'
                width='100px'
                fontWeight={500}
                margin='auto 10px auto 0'
                bgColor={isEditAddress || (me.deliveryInfo && me.deliveryInfo.length) ? orange : gray}
                handleClick={isEditAddress || (me.deliveryInfo && me.deliveryInfo.length) ? handleSelectAddressModal : handleClose}
                icon={isEditAddress || (me.deliveryInfo && me.deliveryInfo.length) ? <ArrowBackIosNewTwoToneIcon sx={iconStyle} /> : <></>} 
                text={
                  <Typography sx={textStyle}>
                    {isEditAddress || (me.deliveryInfo && me.deliveryInfo.length) ? 'Back' : 'Cancel'}
                  </Typography>
                }
              />
              <HMButton 
                type='button'
                width='100px'
                bgColor={green}
                margin='auto 0 auto auto'
                disabled={disabledDilivery}
                handleClick={isEditAddress ? handleEditAddressSave : handleAddAddressSave}
                text={
                  <Typography sx={textStyle}>
                    Save
                  </Typography>
                }
              />
            </HMBox>
          </HMBox>
        ) : isDeleteAddress ? (
          <HMBox
            padding='0'
            width='100%'
            minWidth={!isTabletScreen && '500px'}
          > 
            <Typography sx={deleteTextStyle}>
              Are you sure your want to delete the address? Once you click on yes, it cannot be undone! 
            </Typography>
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='15px auto 0 auto'
              className='change-store-button fade-in'
            >
              <HMButton 
                type='button'
                width='100px'
                fontWeight={500}
                bgColor={orange}
                margin='auto 10px auto 0'
                handleClick={handleSelectAddressModal}
                text={<Typography sx={textStyle}>No, Back!</Typography>}
                icon={isEditAddress || (me.deliveryInfo && me.deliveryInfo.length) ? <ArrowBackIosNewTwoToneIcon sx={iconStyle} /> : <></>} 
              />
              <HMButton 
                type='button'
                width='100px'
                color={white}
                bgColor={red}
                margin='auto 0 auto auto'
                handleClick={handleDeleteAddress}
                text={<Typography sx={textStyle}>Yes, Delete!</Typography>}
              />
            </HMBox>
          </HMBox>
        ) : isStoreDeliveryType ? (
          <HMBox
            width='100%'
            display='flex'
            flexDirection='column'
          >
            {storeDelivery.map((item, index) => (
              <HMBox
                key={index}
                width='100%'
                display='flex'
                cursor='pointer'
                className='fade-in'
                bgColor={lightBlue}
                flexDirection='column'
                hoverBgColor={lightGreen}
                margin={index === 0 ? '15px 0 5px 0' : '5px 0'}
                handleClick={() => boxHandleSelectStoreDeliveryType(item.type)}
              >
                <HMBox
                  padding='0'
                  width='100%'
                  display='flex'
                  color={darkBlue}
                  margin='auto auto 5px auto'
                >
                  <HMText
                    margin='auto 0'
                    fontWeight={500}
                    fontSize={`${fontSize}px`}
                    text={`${item.type} Delivery`}
                  />
                  <HMText
                    color={orange}
                    fontWeight={500}
                    margin='auto 0 auto auto'
                    fontSize={`${fontSize}px`}
                    text={`${currency} ${item.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                  />
                </HMBox>
                <HMBox
                  padding='0'
                  width='100%'
                  display='flex'
                  margin='5px auto auto auto'
                >
                  <AccessTimeTwoToneIcon 
                    sx={{
                      fontSize: `${fontSize}px`,
                      margin: 'auto 5px auto 0',
                    }}
                  />
                  <HMText
                    color={blue}
                    margin='auto 0'
                    text={item.duration}
                    fontSize={`${fontSize - 2}px`} 
                  />
                  <Radio
                    size='small'
                    value={item.type}
                    name='radio-buttons'
                    inputProps={{ 'aria-label': 'A' }}
                    onChange={handleSelectStoreDeliveryType}
                    checked={item.type === selectedStoreDeliveryType}
                    className={`listing-radio-button ${item.type === selectedStoreDeliveryType && 'checked'}`}
                    sx={{
                      margin: 'auto 0 auto auto',
                    }}
                  />
                </HMBox>
              </HMBox>
            ))}
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='15px auto 0 auto'
              className='change-store-button fade-in'
            >
              <HMButton 
                type='button'
                width='100px'
                bgColor={gray}
                fontWeight={500}
                margin='auto 10px auto 0'
                handleClick={handleClose}
                text={<Typography sx={textStyle}>Cancel</Typography>}
              />
              <HMButton 
                type='button'
                width='100px'
                bgColor={green}
                margin='auto 0 auto auto'
                handleClick={handleChangeStoreDeliveryType}
                text={<Typography sx={textStyle}>Confirm</Typography>}
              />
            </HMBox>
          </HMBox>
        ) : (isViewAddedInfo) ? (
          <HMViewAddedInfo 
            addedInfo={currentAddedInfo}
          />
        ) : isSelectPaymentMethod ? (
          <HMPaymentOptions 
            handleExit={handleClose}
            paymentInfo={paymentDummyData}
            selectedPaymentInfo={selectedPaymentInfo}
            handleSelectPaymentInfo={handleSelectPaymentInfo}
            handleAddPaymentMethodModal={handleAddPaymentMethodModal}
            handleConfirmPaymentMethod={handleAddPaymentMethodConfirm}
            handleSetPaymentInfo={(info) => handleSetPaymentInfo(info)}
          />
        ) : isAddPaymentMethod ? (
          <HMPaymentMethods 
            isShowBackIcon={true}
            handleBack={handleSelectPaymentInfoModal}
            handleAddPayment={(method) => handleAddSpecificPaymentMethodModal(method)}
          />
        ) : isAddSpecificPaymentMethod ? (
          <HMPaymentMethod 
            mtnFormData={mtnFormData}
            cardFormData={cardFormData}
            airtelFormData={airtelFormData}
            paymentType={specificPaymentMethod.type}
            handleMtnDataChange={handleMtnDataChange}
            handleCardDataChange={handleCardDataChange}
            disabledPaymentMethod={disabledPaymentMethod}
            handleAirtelDataChange={handleAirtelDataChange}
            handleSavePaymentMethod={handleSavePaymentMethod}
            handleAddPaymentMethodModal={handleAddPaymentMethodModal}
            handleAddPaymentMethodConfirm={handleAddPaymentMethodConfirm}
          />
        ) : isAddInsurer ? (
          <>
            <HMBox
              width='100%'
              display='flex'
              overflowY='auto'
              color={darkBlue}
              flexDirection='column'
              maxHeight={
                isVerySmallHeightScreen ? '50vh' 
                : isSmallHeightScreen ? '60vh' 
                : isNormalHeightScreen ? '70vh' 
                : isBigHeightScreen ? '80vh' 
                : '80vh'
              }
            >
              {qualifiedInsurers.length ? qualifiedInsurers.map((insurer, index) => (
                <HMBox
                  padding='0'
                  key={index}
                  width='100%'
                  display='flex'
                  className='fade-in'
                  flexDirection='column'
                >
                  <PaymentMethodDetails 
                    hoverBgColor={lightGreen}
                    upperText={insurer.name}
                    bgColor={seeThroughLightBlue}
                    lowerText={`${insurer.type} | ${insurer.origin}`}
                    handleClick={() => handleAddSpecificInsurerModal(insurer)}
                    leftIcon={
                      insurer.logo ? (
                        <img
                          alt={insurer.name}
                          src={insurer.logo}
                          className='super-mini-image'
                          style={{ margin: 'auto 10px auto 0' }}
                        />
                      ) : (
                        <Grid className='super-mini-logo' sx={{margin: 'auto 10px auto 0'}}>
                          <HMText
                            float='none'
                            width='100%'
                            margin='auto'
                            height='100%'
                            color={white}
                            fontWeight={700}
                            textAlign='center'
                            bgColor={getRandomColor()}
                            text={insurer.name.substr(0, 1).toUpperCase()}
                          />
                        </Grid>
                      )
                    }
                    rightIcon={
                      <ChevronRightOutlinedIcon 
                        sx={{
                          margin: 'auto 0 auto auto'
                        }} 
                      />
                    }
                  />
                </HMBox>
              )) : <></>}
            </HMBox>
            {Object.keys(chosenInsuranceInfo).length ? (
              <IconButton   
                onClick={handleSelectInsurerModal} 
                sx={{
                  top: '5px',
                  left: '5px',
                  background: orange,
                  position: 'absolute',
                  '&:hover': {
                    background: orange,
                  }
                }}
              >
                <ArrowBackIosNewTwoToneIcon 
                  sx={{
                    color: white,
                    margin: 'auto',
                    fontSize: `${fontSize + 2}px`
                  }}
                />
              </IconButton>
            ) : <></>}
          </>
        ) : (isAddSpecificInsurer || isEditInsurer) ? (
          <HMInsuranceCardForm 
            backOrCancelText='Back'
            cardImageUrl={cardImageUrl}
            isTabletScreen={isTabletScreen}
            setCardImageUrl={setCardImageUrl}
            insurerFormData={insurerFormData}
            handleSaveInsurer={handleSaveInsurer}
            isAddSpecificInsurer={isAddSpecificInsurer}
            handleInsurerDataChange={handleInsurerDataChange}
            handleConfirm={isEditInsurer ? handleEditInsurerConfirm : handleAddInsurerConfirm}
            handleBackOrCancel={isEditInsurer ? handleSelectInsurerModal : handleAddInsurerModal}
          />
        ) : isSelectInsurer ? (
          <HMBox
            padding='0'
            width='100%'
            display='flex'
            className='fade-in'
            flexDirection='column'
          >
            <HMButton 
              width='100%'
              color={blue}
              type='button'
              padding='10px'
              bgColor='inherit'
              borderRadius={10}
              paddingActive='10px'
              border={`1px solid ${blue}`}
              margin='20px auto 10px auto'
              handleClick={handleAddInsurerModal}
              minWidth={!isTabletScreen && '400px'}
              icon={<AddIcon sx={{...iconStyle, margin: 'auto 0 auto auto'}} />} 
              text={
                <Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>
                  Add insurer information
                </Typography>
              } 
            />
            {(me.insuranceInfo && me.insuranceInfo.length) ? (
              <HMBox
                padding='0'
                width='100%'
                display='flex'
                flexDirection='column'
              >
                {me.insuranceInfo.map((data, index) => (
                  <HMBox
                    padding='0'
                    key={index}
                    width='100%'
                    display='flex'
                    flexDirection='column'
                  >
                    <PaymentMethodDetails 
                      hoverBgColor={lightGreen}
                      lowerText={data.nameOnCard}
                      bgColor={seeThroughLightBlue}
                      handleClick={() => handleSelectInsuranceInfo(data)}
                      upperText={`${data.insurer.name} - ${data.cardNumber}`}
                      notClickable={!qualifiedInsurersObj[data.insurer._id] ? true : false}
                      leftIcon={
                        data.insurer.logo ? (
                          <img
                            alt={data.insurer.name}
                            src={data.insurer.logo}
                            className='super-mini-image'
                            style={{ margin: 'auto 10px auto 0' }}
                          />
                        ) : (
                          <Grid className='super-mini-logo' sx={{margin: 'auto 10px auto 0'}}>
                            <HMText
                              float='none'
                              width='100%'
                              margin='auto'
                              height='100%'
                              color={white}
                              fontWeight={700}
                              textAlign='center'
                              bgColor={getRandomColor()}
                              text={data.insurer.name.substr(0, 1).toUpperCase()}
                            />
                          </Grid>
                        )
                      }
                      rightIcon={
                        <>
                          {data.default ? (
                            <HMText
                              color={white}
                              padding='0 5px'
                              bgColor={orange}
                              text='Dafault insurance'
                              margin='auto 10px auto auto'
                              fontSize={`${fontSize - 2}px`}
                            />
                          ) : <></>}
                          <IconButton   
                            onClick={(e) => handleEditInsurerModal(e, data, data.insurer, index)} 
                            sx={{margin: data.default ? 'auto 0 auto 5px' : 'auto 0 auto auto'}}
                          >
                            <DriveFileRenameOutlineOutlinedIcon 
                              sx={{
                                margin: 'auto',
                                fontSize: `${fontSize + 4}px`,
                              }}
                            />
                          </IconButton>
                        </>
                      }
                    />
                  </HMBox>
                ))}
              </HMBox>
            ) : <></>}
          </HMBox>
        ) : <></>}
      </HMModal>
      {me && checkoutDetails.length ? (
        <Section
          padding={10}
          bgColor={white}
        >
          <Grid container spacing={2}>
            <Grid 
              item 
              xs={12} 
              sx={{display: 'flex', flexDirection: 'column'}}
              sm={isNormalTabletScreen || (isMediumScreen && visible) ? 12 : 8} 
            >
              {Object.keys(chosenAddressInfo).length ? (
                <AddressDetails 
                  bgColor={skeletonsLightBlue}
                  isTabletScreen={isTabletScreen}
                  addressInfo={chosenAddressInfo}
                  handleChangeClick={handleSelectAddressModal}
                />
              ) : (!isLoading && ((('deliveryInfo' in me) && !me.deliveryInfo.length) || !('deliveryInfo' in me))) ? (
                <HMBox
                  width='100%'
                  display='flex'
                  className='fade-in'
                >
                  <HMButton 
                    width='100%'
                    type='button'
                    color={blue}
                    margin='auto'
                    padding='10px'
                    bgColor='inherit'
                    borderRadius={10}
                    paddingActive='10px'
                    border={`1px solid ${blue}`}
                    handleClick={handleAddAddressModal}
                    icon={<AddIcon sx={{...iconStyle, margin: 'auto 0 auto auto'}} />} 
                    text={
                      <Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>
                        Add delivery address
                      </Typography>
                    } 
                  />
                </HMBox>
              ) : <></>}
              {checkoutDetails.length ? (
                <HMBox
                  width='100%'
                >
                  <HMAccordion
                    className='checkout'
                    isOnlineTitle={true}
                    title='Order Details'
                  >
                    {checkoutDetails.map((checkout, index1) => (
                      <HMBox
                        key={index1}
                        width='100%'
                        display='flex'
                        bgColor={white}
                        color={lightBlack}
                        margin='0 0 10px 0'
                        flexDirection='column'
                      >
                        <HMBox
                          width='100%'
                          display='flex'
                        >
                          <Link
                            sx={{margin: 'auto 0', cursor: 'pointer'}}
                            onClick={() => navegateToStorePage(checkout.store)}
                          >
                            <HMText
                              color={blue}
                              margin='auto 0'
                              text={checkout.storeName}
                              fontSize={`${fontSize - 1}px`}
                            />
                          </Link>
                          <HMText
                            padding='0 10px'
                            bgColor={lightBlue}
                            margin='auto 0 auto 5px'
                            text={checkout.storeType}
                            fontSize={`${fontSize - 1}px`}
                          />
                          {!isTabletScreen ? (
                            <>
                              <AccessTimeTwoToneIcon 
                                sx={{
                                  fontSize: `${fontSize}px`,
                                  margin: 'auto 0 auto auto',
                                }}
                              />
                              <HMText
                                color={blue}
                                margin='auto 0 auto 5px'
                                fontSize={`${fontSize - 2}px`} 
                                text={checkout.deliveryOption.duration}
                              />
                              <HMText
                                text='|'
                                margin='auto 0'
                                padding='0 10px'
                                fontSize={`${fontSize - 1}px`}
                              />
                            </>
                          ) : <></>}
                          <HMText
                            fontSize={`${fontSize - 1}px`}
                            text={checkout.deliveryOption.type}
                            margin={isTabletScreen ? 'auto 0 auto auto' : 'auto 0'}
                          />
                          <IconButton   
                            sx={{margin: 'auto 0 auto 2.5px'}}
                            onClick={() => handleChangeStoreDeliveryTypeModal(checkout.store)} 
                          >
                            <DriveFileRenameOutlineOutlinedIcon 
                              sx={{
                                margin: 'auto',
                                fontSize: `${fontSize}px`,
                              }}
                            />
                          </IconButton>
                        </HMBox>
                        {checkout.checkoutItems.map((item, index2) => (
                          <HMBox
                            width='100%'
                            key={index2}
                            display='flex'
                            margin='0 0 5px 0'
                            bgColor={skeletonsLightBlue}
                          >
                            <Link
                              sx={{margin: 'auto 0', cursor: 'pointer'}}
                              onClick={() => navegateToProductPage(item)}
                            >
                              <img 
                                alt={item.productName}
                                className='small-image'
                                src={item.productImage}
                              />
                            </Link>
                            <HMBox
                              width='100%'
                              height='50px'
                              display='flex'
                              padding='0 5px'
                              margin='auto 0'
                              flexDirection='column'
                            >
                              <HMText
                                margin='0'
                                text={item.productName}
                                fontSize={`${fontSize - 1}px`}
                              />
                              <HMBox
                                padding='0'
                                display='flex'
                                margin='auto 0 2.5px 0'
                              >
                                <HMText
                                  margin='auto 2.5px auto 0'
                                  text={`Qty: ${item.quantity}`}
                                  fontSize={`${fontSize - 1}px`}
                                />
                                <HMText
                                  text='|'
                                  margin='auto 0'
                                  padding='0 5px'
                                  fontSize={`${fontSize - 1}px`}
                                />
                                <HMText
                                  margin='auto 0 auto 2.5px'
                                  fontSize={`${fontSize - 1}px`}
                                  text={`${currency} ${item.onlinePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                                />
                                {item.addedInfo && Object.keys(item.addedInfo).length ? (
                                  <HMButton 
                                    type='button'
                                    padding='0 5px'
                                    bgColor={green}
                                    fontWeight={500}
                                    paddingActive='0 4.5px'
                                    margin='auto 0 auto auto'
                                    icon={<RemoveRedEyeIcon sx={iconStyle} />} 
                                    handleClick={
                                      item.addedInfo.uploadedPrescription 
                                      ? () => handleViewPrescription(item.addedInfo.prescription) 
                                      : () => handleViewAddedInfoModal(item.addedInfo)
                                    }
                                    text={
                                      <Typography sx={{...textStyle, fontSize: `${fontSize - 2}px`}}>
                                        {item.addedInfo.uploadedPrescription ? 'View prescription' : 'View additinal info'}
                                      </Typography>
                                    }
                                  />
                                ) : <></>}
                              </HMBox>
                            </HMBox>
                          </HMBox>
                        ))}
                        <HMBox
                          width='100%'
                          display='flex'
                        >
                          <HMText
                            margin='auto 0 auto auto'
                            fontSize={`${fontSize - 1}px`}
                            text={`${checkout.quantity} ${checkout.quantity > 1 ? 'items' : 'item'}, Subtotal:`}
                          />
                          &nbsp;
                          <HMText
                            fontWeight={500}
                            margin='auto 10px auto 0'
                            fontSize={`${fontSize}px`}
                            text={`${currency} ${checkout.subtotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                          />
                        </HMBox>
                      </HMBox>
                    ))}
                  </HMAccordion>
                </HMBox>
              ) : <></>}
              <HMBox
                padding='0'
                width='match-content'
                margin='0 5px 10px 5px'
              >
                <HMBox
                  padding='0'
                  width='100%'
                  margin='auto'
                  display='flex'
                >
                  <HMText 
                    width='100%'
                    fontWeight={500}
                    text='Payment Method'
                    margin='15px 0 10px 0'
                    fontSize={`${fontSize}px`}
                  /> 
                  {!useInsurer ? (
                    <HMBox
                      padding='0'
                      width='100%'
                      color={blue}
                      display='flex'
                      cursor='pointer'
                      className='fade-in'
                      hoverColor={orange}
                      margin='auto 0 auto auto'
                      handleClick={handleSelectPaymentInfoModal}
                    >
                      <HMText
                        color='inherit'
                        text='More options'
                        margin='auto 0 auto auto'
                        fontSize={`${fontSize}px`}
                      />
                      <ChevronRightOutlinedIcon 
                        sx={{
                          color: 'inherit',
                          margin: 'auto 0',
                          fontSize: `${fontSize + 4}px`
                        }}
                      />
                    </HMBox>
                  ) : <></>}
                </HMBox>
                <HMBox
                  padding='0'
                  width='100%'
                  margin='auto'
                  display='flex'
                  className='fade-in'
                  flexDirection={isTabletScreen ? 'column' : 'row'}
                >
                  <HMPaymentOption 
                    width='100%'
                    isBig={true}
                    height='100%'
                    paymentInfo={confirmedPaymentInfo}
                    selectedPaymentInfo={confirmedPaymentInfo}
                    handleSelectPaymentInfo={handleSelectPaymentInfo}
                    margin={isTabletScreen ? '5px auto' : 'auto 5px auto auto'}
                    handleSetPaymentInfo={(info) => handleSetPaymentInfo(info)}
                  />
                  {!useInsurer ? (
                    <HMButton 
                      width='100%'
                      type='button'
                      color={blue}
                      padding='10px'
                      bgColor='inherit'
                      borderRadius={10}
                      className='fade-in'
                      paddingActive='10px'
                      height='match-content'
                      border={`1px solid ${blue}`}
                      handleClick={handleAddPaymentMethodModal}
                      margin={isTabletScreen ? '5px auto' : '0 0 0 5px'}
                      icon={<AddIcon sx={{...iconStyle, margin: 'auto 0 auto auto'}} />} 
                      text={
                        <Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>
                          Add payment method
                        </Typography>
                      } 
                    />
                  ) : <></>}
                </HMBox>
              </HMBox>
              {Object.keys(insuredCheckoutItemsObj).length ? (
                <HMBox
                  padding='0'
                  className='fade-in'
                  width='match-content'
                  margin='0 5px 10px 5px'
                >
                  <HMBox
                    padding='0'
                    width='100%'
                    margin='auto'
                    display='flex'
                  >
                    <HMText 
                      width='100%'
                      fontWeight={500}
                      margin='15px 0 10px 0'
                      text='Pay With Insurance'
                      fontSize={`${fontSize}px`}
                    /> 
                    <HMToogleButton 
                      status={useInsurer}
                      margin='auto 0 auto auto'
                      text={useInsurer ? 'Yes' : 'No'}
                      handleChange={() => setUseInsurer(!useInsurer)}
                    />
                  </HMBox>
                  {(useInsurer && Object.keys(chosenInsuranceInfo).length) ? (
                    <>
                      <HMBox
                        padding='0'
                        width='100%'
                        display='flex'
                        className='fade-in'
                        flexDirection={isTabletScreen ? 'column' : 'row'}
                      >
                        <HMInsuranceCard 
                          isCheckoutPage={true}
                          insuranceInfo={chosenInsuranceInfo}
                          margin={isTabletScreen ? '5px auto' : 'auto 5px auto 0'}
                          bgColor={itemsSupportedByChosenInsurer.length ? seeThroughLightBlue : lightRed}
                          insurer={Object.keys(chosenInsurer).length ? chosenInsurer : chosenInsuranceInfo.insurer}
                        />
                        <HMButton 
                          width='100%'
                          type='button'
                          color={blue}
                          padding='10px'
                          bgColor='inherit'
                          borderRadius={10}
                          className='fade-in'
                          paddingActive='10px'
                          height='match-content'
                          border={`1px solid ${blue}`}
                          handleClick={handleSelectInsurerModal}
                          margin={isTabletScreen ? '5px auto' : '0 0 0 5px'}
                          rightIcon={<ChevronRightOutlinedIcon sx={{fontSize: `${fontSize + 2}px`, margin: 'auto auto auto 5px'}} />} 
                          text={
                            <Typography sx={{...textStyle, margin: 'auto 5px auto auto'}}>
                              More options
                            </Typography>
                          } 
                        />
                      </HMBox>
                      <HMBox
                        padding='0'
                        width='100%'
                        display='flex'
                        margin='10px 0 0 0'
                        flexDirection='column'
                        bgColor={itemsSupportedByChosenInsurer.length && superLightBlue}
                      >
                        {itemsSupportedByChosenInsurer.length ? (
                          <HMAccordion
                            isOnlineTitle={true}
                            title='Insured products'
                            className='checkout insurance'
                          >
                            <HMAlert 
                              warning={true}
                              margin='0 0 10px 0'
                              bgColor={lightWarning}
                              fontSize={fontSize - 2}
                              text='Note that your insurance is not applicable until is validated!.'
                            />
                            {itemsSupportedByChosenInsurer.map((data, index) => {
                              const checkout = checkoutDetails.find(checkout => checkout.checkoutItems.find(item => item.listing === data.listing))
                              return (
                                <CartListings 
                                  key={index}
                                  item={data}
                                  cart={checkout}
                                  bgColor={white}
                                  forInsurance={true}
                                  handleProduct={() => navegateToProductPage(data)}
                                  handleStore={() => navegateToStorePage(checkout.store)}
                                />
                              )
                            })}
                          </HMAccordion>
                        ) : (
                          <HMAlert 
                            error={true}
                            bgColor={lightRed}
                            margin='0 0 10px 0'
                            fontSize={fontSize - 2}
                            text={`The chosen insurer "${chosenInsurer.name}" does not support any product in your checkout!`}
                          />
                        )}
                      </HMBox>
                    </>
                  ) : (useInsurer && !Object.keys(chosenInsuranceInfo).length) ? (
                    <HMBox
                      padding='0'
                      width='100%'
                      display='flex'
                      className='fade-in'
                    >
                      <HMButton 
                        width='100%'
                        color={blue}
                        type='button'
                        margin='auto'
                        padding='10px'
                        bgColor='inherit'
                        borderRadius={10}
                        paddingActive='10px'
                        border={`1px solid ${blue}`}
                        handleClick={handleAddInsurerModal}
                        icon={<AddIcon sx={{...iconStyle, margin: 'auto 0 auto auto'}} />} 
                        text={
                          <Typography sx={{...textStyle, margin: 'auto auto auto 5px'}}>
                            Add insurance information
                          </Typography>
                        } 
                      />
                    </HMBox>
                  ) : <></>}
                </HMBox>
              ) : <></>}
            </Grid>
            {Object.keys(checkoutComputation).length ? (
              <Grid item xs={12} sm={isNormalTabletScreen || (isMediumScreen && visible) ? 12 : 4}>
                {(() => {
                  const {
                    total,
                    subtotal,
                    deliveryCost,
                    numberOfStores
                  } = checkoutComputation
                  return (
                    <HMOrderComputation 
                      tax={tax}
                      total={total}
                      isCheckout={true}
                      subtotal={subtotal}
                      title='Order Summary'
                      deliveryCost={deliveryCost}
                      actionMessage='Place order'
                      numberOfStores={numberOfStores}
                      isDisabled={isPlaceOrderDisabled}
                      handleProceed={handleProceedToPlaceOrder}
                      margin={
                        isNormalTabletScreen || (isMediumScreen && visible) ? 'auto' 
                        : orderSummaryTop > 50 ? `${orderSummaryTop}px auto auto auto`
                        : 'auto' 
                      }
                    /> 
                  )
                })()}
              </Grid>
            ) : <></>}
          </Grid>
        </Section>
      ) : !user ? (
        <HMLoginFirst 
          message='You need to login first before accessing your checkout page'
        />
      ) : <></>}
    </Page>
  )
}

export default Checkout