import React from 'react'
import Typography from '@mui/material/Typography'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HMBox from '../layout/HMBox'
import {
    fontSize,
    textStyle,
} from './Styles'
import {
    red,
    blue,
    green,
    orange,
    lightRed,
    lightBlue,
    lightGreen,
    lightWarning,
} from '../../../hooks/useColors'

function HMAlert(props) {
    const messageStyle = {
        ...textStyle, 
        fontStyle: props.fontStyle,
        textAlign: props.textAlign, 
        margin: props.textMargin || 'auto 0 auto 10px',
        fontSize: `${props.fontSize || fontSize - 2}px`,
    }
    const iconStyle = {
        padding: '0',
        color: orange,
        margin: 'auto 0',
    }
    return (
        <HMBox
            display='flex'
            className='fade-in'
            height={props.height}
            margin={props.margin}
            width={props.width || '100%'}
            bgColor={
                props.good ? lightBlue
                : props.error ? lightRed
                : props.success ? lightGreen
                : props.warning ? lightWarning
                : props.bgColor
            }
        >
            {props.success ? (
                <CheckCircleIcon sx={{...iconStyle, color: green}} />
            ) : props.good ? (
                <CheckCircleIcon sx={{...iconStyle, color: blue}} />
            ) : props.warning ? (
                <WarningIcon sx={{...iconStyle, color: orange}} />
            ) : props.error ? (
                <ErrorOutlinedIcon sx={{...iconStyle, color: red}} />
            ) : <></>}
            <Typography 
                sx={messageStyle}
            >
                {props.text}
            </Typography>
        </HMBox>
    )
}

export default HMAlert