import React from 'react'
import HMBox from '../layout/HMBox';
import DotAndLabel from '../../analytics/DotAndLabel';

function HMListItems(props) {
    
    return (
        <HMBox 
            width='100%'
            padding='0'
            display='flex'
            flexDirection='column'
            overflowX='auto'
        >
            {props.listItems.map((item, index) => (
                <DotAndLabel 
                    key={index}
                    title={item}
                    dotColor={props.dotColor}
                    fontWeight={props.fontWeight}
                />
            ))}
        </HMBox>
    )
}

export default HMListItems