import React from 'react'
import parse from 'html-react-parser'
import HMListItems from './HMListItems'
import HMText from './HMText'
import Title from './Title'
import { fontSize } from './Styles'
import HMBox from '../layout/HMBox'
import { lightBlack, lightBlue } from '../../../hooks/useColors'

function ProductDetail(props) {
    const listItems = props.isList 
        ? props.text.split(',').map(item => item.trim().charAt(0).toUpperCase() + item.trim().slice(1)) 
        : []
    
    return props.text ? (
        <HMBox
            display='flex'
            margin={props.margin}
            width={props.width || '100%'}
            padding={props.padding || '0'}
            flexDirection={props.flexDirection || 'column'}
        >
            <Title
                width='100%'
                textAlign='left'
                title={props.title}
                size={props.titleSize || fontSize}
                margin={props.titleMargin || '10px 0 5px 0'}
            />
            <HMBox
                width='100%'
                display='flex'
                bgColor={props.bgColor || lightBlue}
                minHeight={props.title.toLowerCase() === 'description' ? '100px' : 'auto'}
                className={props.title.toLowerCase() === 'description' ? 'description' : props.className}
            >
                {props.title.toLowerCase() === 'description' ? (
                    <div style={{ textAlign: 'justify' }}>
                        {parse(props.text)}
                    </div>
                ) : props.isList ? (
                    <HMListItems 
                        fontWeight={400}
                        dotColor={lightBlack}
                        listItems={listItems.sort((a, b) => a.length - b.length)}
                    />
                ) : (
                    <HMText
                        margin='auto 0'
                        text={props.text}
                        textAlign='justify'
                    />
                )}
            </HMBox>
        </HMBox>
    ) : <></>
}

export default ProductDetail