import apiClient from '../apiClient'

const API_URL_INSURERS = '/api/v1/insurers'

// Get all insurers
const getInsurers = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.get(API_URL_INSURERS, config)
    
    const data = response.data

    return data
}

// Get a insurers
const getInsurer = async (insurerID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.get(`${API_URL_INSURERS}/${insurerID}`, config)

    const data = response.data
 
    return data
}
// Create a new insurer
const createInsurer = async (newInsurer, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.post(API_URL_INSURERS, newInsurer, config)

    const data = response.data
 
    return data
}

// Update a insurer
const updateInsurer = async (updateInsurer, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.post(`${API_URL_INSURERS}/${updateInsurer._id}`, updateInsurer, config)

    const data = response.data
    
    return data
}

// Update a insurer
const deleteInsurer = async (insurerID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.delete(`${API_URL_INSURERS}/${insurerID}`, config)

    const data = response.data

    return data
}

const insurerService = {
    createInsurer,
    updateInsurer,
    deleteInsurer,
    getInsurers,
    getInsurer
}
export default insurerService