import mtn from '../assests/mtn.png'
import coins from '../assests/coins.png'
import airtel from '../assests/airtel.png'
import insurance from '../assests/insurance.png'
import card from '../assests/debit-card.png'
import order_notification from '../assests/audio/order_notification.wav'
import { 
  red, 
  blue, 
  green, 
  orange, 
  darkBlue, 
  strongRed, 
} from './useColors'

export const MAX_PURCHASE_PER_UNIT = 100

export const storeTypes = [
  'Pharmacy',
  'Health Care'
]
export const supplierTypes = [
  'Medical Supplier',
  'Health Care Supplier'
]
export const insurerTypes = [
  'Public',
  'Private',
]
export const insurerOrigins = [
  'Local',
  'International',
]
export const orderStatus = [
  'Pending', // ==> Done by the system
  'Canceled', // ==> Done by either a customer or a store
  'Refunded', // ==> Done by a store
  'Returned', // ==> Done by the system after customer returns the item
  'Received', // ==> Done by a customer (Notice that this can be ignore mostly and we should not depend on it match)
  'Completed', // ==> Done by a store
  'Accepted', // ==> Done by a delivery agent
  'Delivered', // ==> Done by a delivery agent
  'Delivering', // ==> Done by a delivery agent
  'Processing', // ==> Done by a store
]
export const storeOrderStatus = [
  'Canceled',
  // 'Returned',
  'Completed',
]
export const storeApplicationStatus = [
  'Submitted',
  'Re-Applied',
  'Received',
  'Rejected',
  'Approved'
]
export const supplierApplicationStatus = [
  'Submitted',
  'Re-Applied',
  'Received',
  'Rejected',
  'Approved'
]
export const genders = [
  'Male',
  'Female',
]
export const storeDelivery = [
  {type: 'Standard', cost: 1500, duration: '1 hour - 2 hours'},
  {type: 'Urgent', cost: 2000, duration: '45 min - 1 hour'}
]
export const paymentMethodDetails = [
  {type: 'COD', title: 'Cash On Delivery', icon: coins, size: { width: '30px' , height: '20px' } },
  {type: 'card', title: 'Credit/Debit Card', icon: card, size: { width: '30px' , height: '20px' } },
  {type: 'mtn', title: 'MTN Mobile Money', icon: mtn, size: { width: '30px' , height: '15px' } },
  {type: 'airtel', title: 'Airtel Money', icon: airtel, size: { width: '30px' , height: '20px' } },
  {type: 'insurance', title: 'Health Insurance', icon: insurance, size: { width: '30px' , height: '20px' } }
]
export const currencies = [
  { country: 'Rwanda', currency: 'RF' }
]
export const accessLevels = [
  { role: 'HarakaMeds Super Admin', level: 0},
  { role: 'HarakaMeds Admin', level: 1},
  { role: 'HarakaMeds Operator', level: 5 },
  { role: 'Super Admin', level: 20 },
  { role: 'Insurer Operator', level: 30 },
  { role: 'Supplier Admin', level: 40 },
  { role: 'Supplier Manager', level: 41 },
  { role: 'Supplier Operator', level: 45 },
  { role: 'Store Admin', level: 50 },
  { role: 'Store Manager', level: 51 },
  { role: 'Store Operator', level: 55 },
  { role: 'Delivery Agent', level: 60 },
  { role: 'Customer', level: 100 }
]

export const cloudinary = {
  API_URL_PRODUCTS: "/api/v1/files/products/upload",
  API_URL_USERS: "/api/v1/files/users/upload",
  API_URL_LOGOS: "/api/v1/files/logos/upload",
  API_URL_FDA: "/api/v1/files/fda/upload",
  API_URL_PRESCRIPTION:  "/api/v1/files/prescription/upload",
}

export const userRole = (accessLevel) => {
  const access = accessLevels.find(a => a.level === accessLevel)
  return access ? access.role : `Unknown (${accessLevel})`
}

export const computateOrderData = (currentOrder) => {
  const orderDetails = Object.keys(currentOrder).length ? currentOrder.orderDetails : []
  let total = 0 
  let subtotal = 0
  let deliveryCost = 0
  let numberOfStores = 0 
  let insuranceCoverage = 0

  if (orderDetails.length) {
    for (let order of orderDetails) {
      numberOfStores++
      deliveryCost += order.status.toLowerCase() === 'canceled' || !order.deliveryOption ? 0 : order.deliveryOption.cost
      for (let item of order.orderItems) {
        let cost = 0
        if (order.approvedInsurance && item.usedInsurance) {
          cost = item.quantity * item.insurancePrice 
          insuranceCoverage += order.status.toLowerCase() === 'canceled' ? 0 : (cost * (currentOrder.insuranceInfo.storeValidation[order.store].coverage / 100))
        } else {
          cost = item.quantity * item.onlinePrice
        }
        subtotal += cost
        total += order.status.toLowerCase() === 'canceled' ? 0 : cost
      }
      total += deliveryCost
    }
  }

  return {
    total,
    subtotal,
    deliveryCost,
    numberOfStores,
    insuranceCoverage
  }
}
export const computeSubtotal = (orderItems, approvedInsurance) => {
  let subtotal = 0
  for (let item of orderItems) {
    subtotal += item.quantity * ( approvedInsurance && item.usedInsurance ? item.insurancePrice : item.onlinePrice )
  }
  return subtotal
}

export const isValidEmail = (email) => {
  let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  return email.match(mailformat) ? true : false
}

export const excludeKeysFromObject = (obj, keys) => {
  let target = {}
  for (let i in obj) {
    if (keys.indexOf(i) >= 0) {
      continue
    }
    if (!Object.prototype.hasOwnProperty.call(obj, i)) {
      continue
    }
    target[i] = obj[i]
  }
  return target
}

export const removeDuplicates = (arr, type) => {
    let obj = {}
    let result = []
    let format = type || 'string'

    for (let i of arr) {
        obj[i] = (obj[i] || 0) + 1
    }

    for (let key in obj) {
        if (format === 'string') {
            result.push(key.toString())
        } else if (format === 'number') {
            result.push(parseInt(key))
        }
    }
    return result
}
export const getReadableSuppplierListings = (supplierWithListings) => {
  const readableSupplierListings = []
  if (Object.keys(supplierWithListings).length) {
    supplierWithListings.productsAndListings.forEach(data => {
        const listing = {
            name: data.product.name,
            image: data.product.image,
            _id: data.supplierListing._id,
            price: data.supplierListing.price,
            active: data.supplierListing.active,
            archive: data.supplierListing.archive,
            soldOnline: data.supplierListing.soldOnline,
            stockCount: data.supplierListing.stockCount,
            prodCategory: data.product.category.id.name,
            onlinePrice: data.supplierListing.onlinePrice,
            transitStockCount: data.supplierListing.transitStockCount,
            prodParentCategory: data.product.category.id.parent.name,
        }
        readableSupplierListings.push(listing)
    })
    return {
      doneSetting: true,
      readableSupplierListings,
    }
  } else {
    return {
      doneSetting: true,
      readableSupplierListings,
    }
  }
}

export const isInTitle = (title, str1, str2) => {
    return title.toLowerCase().split(' ').includes(str1) || title.toLowerCase().split(' ').includes(str2) ? true : false
}
export const removeProductsThatAlreadyHaveStoreListings = (store, products, storeWithListings) => {
  let options = []
  const doneSetting = true
  if (!Object.keys(storeWithListings).length) {
    options = products.filter(product => {
      return (product.published && !product.archive) ? true : false 
    }).map(product => ({
      ...product,
      searchText: product.brand ? `${product.name} | ${product.brand}` : product.name
    }))
  } else if (Object.keys(storeWithListings).length) {
    const productIdsObj = {}

    for (let data of storeWithListings.productsAndListings) {
      productIdsObj[data.product._id.toString()] = 1
    }

    options = products.filter(product => {
      return (!productIdsObj[product._id.toString()] && product.published && !product.archive) ? true : false 
    }).map(product => ({
      ...product,
      searchText: product.brand ? `${product.name} | ${product.brand}` : product.name
    }))
  } 

  if (store.type !== storeTypes[0]) {
    options = options.filter(product => product.category.id.parent.name.toLowerCase().includes('health'))
  } 

  console.log(options)

  return {
    options,
    doneSetting
  }
}  

export const removeProductsThatAlreadyHaveSupplierListings = (supplier, products, supplierWithListings) => {
  let options = []
  const doneSetting = true
  if (!Object.keys(supplierWithListings).length) {
    options = options = products.filter(product => {
      return (product.published && !product.archive) ? true : false 
    })
  } else if (Object.keys(supplierWithListings).length) {
    const productIdsObj = {}

    for (let data of supplierWithListings.productsAndListings) {
      productIdsObj[data.product._id.toString()] = 1
    }

    options = products.filter(product => {
      return (!productIdsObj[product._id.toString()] && product.published && !product.archive) ? true : false 
    })
  } 

  if (supplier.type !== supplierTypes[0]) {
    options = options.filter(product => product.category.id.parent.name.toLowerCase().includes('health'))
  } 

  return {
    options,
    doneSetting
  }
}

export const generatePassword = (number, symbols, upperCase, lowerCase, length) => {
    const numbersArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    const symbolsArray = ['!', '-', '+', '@', '#', '$', '%', '&', '^', '*', '(', ')', '{', '}']

    const charCodes = Array.from(Array(26)).map((_e, i) => i + 97)
    const lowerCaseLetters = charCodes.map(letter => String.fromCharCode(letter))

    const upperCaseLetters = lowerCaseLetters.map(letter => letter.toUpperCase())

    const availableCharaters = [
      ...(number ? numbersArray : []),
      ...(symbols ? symbolsArray : []),
      ...(upperCase ? upperCaseLetters : []),
      ...(lowerCase ? lowerCaseLetters : [])
    ]

    const shuffleArray = (array) => {
      return array.sort(() => Math.random() - 0.5)
    }

    const characters = shuffleArray(availableCharaters).slice(0, length)

    return characters.join('')
}

export const searchThroughProducts = (text, publicProducts, selectedOption) => {
    const searchFields = ['name']
    const searchData = text.toLowerCase().split(' ').filter(item => item !== '')
    let matchingProducts = []
    if (publicProducts.length) {
      matchingProducts = publicProducts.filter(product => {
        let check = false
        searchData.forEach((item) => {
            for (let i = 0; i < searchFields.length; i++) {
                const field = searchFields[i]
                if (product[field].toLowerCase().includes(item)) {
                    check = true
                } 
            }
        })
        if (check) {
            return product
        } else {
            return false
        }
      })
    }
    
    if (selectedOption !== 'all') {
      matchingProducts = matchingProducts.filter(product => product.category.id.name.toLowerCase().includes(selectedOption.toLowerCase()))
    } 

    return matchingProducts
}

export const getMatchingCategory = (id, publicCategories) => {
  let category = {} 

  const octCat = publicCategories.find(category => category.name.toLowerCase().split(' ').includes('otc'))
  const healthCat = publicCategories.find(category => category.name.toLowerCase().split(' ').includes('health'))
  const preCat = publicCategories.find(category => category.name.toLowerCase().split(' ').includes('prescription'))
  

  if (octCat.children.find(cat => cat._id === id)) {
    const matchingCategory = octCat.children.find(cat => cat._id === id)
    category = {
      id: matchingCategory._id,
      name: matchingCategory.name,
      parent: {
        id: octCat._id,
        name: octCat.name,
      }
    }
  } else if (healthCat.children.find(cat => cat._id === id)) {
    const matchingCategory = healthCat.children.find(cat => cat._id === id)
    category = {
      id: matchingCategory._id,
      name: matchingCategory.name,
      parent: {
        id: healthCat._id,
        name: healthCat.name,
      }
    }
  } else if (preCat.children.find(cat => cat._id === id)) {
    const matchingCategory = preCat.children.find(cat => cat._id === id)
    category = {
      id: matchingCategory._id,
      name: matchingCategory.name,
      parent: {
        id: preCat._id,
        name: preCat.name,
      }
    }
  }

  return category
}

export const getStockAvailability = (availableStock, transitStockCount) => {
  let stockCount = 0
  let expiredStockCount = 0
  let aboutToExpireStockCount = 0
  const epiredStock = []
  const aboutToExpireStock = []
  const tansitStock = transitStockCount || 0

  availableStock.forEach(stock => {
    const expiresOn = new Date(stock.expiresOn)
    const diff = expiresOn.getTime() - Date.now() 
    const days = Math.floor(diff / 1000 / 60 / 60 / 24)
    if (days > 15) {
      stockCount += stock.quantity
    } else {
      epiredStock.push(stock)
      expiredStockCount += stock.quantity
    }

    if (days > 15 && days < 60) {
      aboutToExpireStock.push(stock)
      aboutToExpireStockCount += stock.quantity
    }
  })
  
  return {
    epiredStock,
    expiredStockCount,
    aboutToExpireStock,
    aboutToExpireStockCount,
    stockCount: stockCount - tansitStock > 0 ? stockCount - tansitStock : 0,
  }
}

export const getReadableListings = (storeWithListings) => {
  const readableStoreListings = []
  if (Object.keys(storeWithListings).length) {
    storeWithListings.productsAndListings.forEach(data => {
        const {
          stockCount,
          expiredStockCount,
          aboutToExpireStockCount,
        } = getStockAvailability(data.storeListing.availableStock, data.storeListing.transitStockCount)
        const listing = {
            stockCount,
            expiredStockCount,
            aboutToExpireStockCount,
            name: data.product.name,
            image: data.product.image,
            _id: data.storeListing._id,
            price: data.storeListing.price,
            active: data.storeListing.active,
            archive: data.storeListing.archive,
            soldOnline: data.storeListing.soldOnline,
            onlinePrice: data.storeListing.onlinePrice,
            prodCategory: data.product.category.id.name,
            transitStockCount: data.storeListing.transitStockCount,
            prodParentCategory: data.product.category.id.parent.name,
        }
        readableStoreListings.push(listing)
    })
    return {
      doneSetting: true,
      readableStoreListings,
    }
  } else {
    return {
      doneSetting: true,
      readableStoreListings,
    }
  }
}

export const playNewOrderSound = () => {
  const promise = new Audio(order_notification)

  if (promise) {
    promise.play()
  }
}

export const keepOnlyDigits = (str) => {
  return str.replace(/\D/g, '')
}
export const beautify = (val) => {
  let str = keepOnlyDigits(val.toString())
  let newStr = str
  if (str.length >= 3) {
    newStr = str.substr(0, 3) + ' ' + str.substr(3)
  }
  if (newStr.length >= 7) {
    newStr = newStr.substr(0, 7) + ' ' + newStr.substr(7)
  }
  return newStr
}

export const validatePhoneNumber = (num) => {
  let phoneNumber = keepOnlyDigits(num)
  if (phoneNumber.length <= 9) {
    const isValid = /^7[2-9]{1}[0-9]{7}$/.test(phoneNumber)
    return {
      isValid,
      phoneNumber: parseInt(phoneNumber) 
    }
  } else {
    return null
  }
}

export const stringifyPhoneNumber = (number) => {
  return `+250 ${beautify(number)}`
}

export const timestampToDateString = (timestamp) => {
  const date = new Date(timestamp * 1000)
  const year = date.getFullYear()
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  const hours = ('0' + date.getHours()).slice(-2)
  const minutes = ('0' + date.getMinutes()).slice(-2)
  const seconds = ('0' + date.getSeconds()).slice(-2)
  const dateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  return dateString
}

export const hideEmail = (email) => {
  let [username, domain] = email.split('@')
  const usernameLength = username.length
  const hideLength = Math.floor(usernameLength / 2)
  const startIndex = Math.floor((usernameLength - hideLength) / 2)
  const endIndex = startIndex + hideLength

  username = username.split('')
  for (let i = startIndex; i < endIndex; i++) {
    username[i] = '*'
  }
  username = username.join('')

  return `${username}@${domain}`
}

export const getFileType = (file) => {
  const tempFileName = file.split('/').pop()
  const tempFileType = tempFileName.split('.').pop()
  let fileType = ''
  if (tempFileType === 'csv') {
    fileType = 'csv'
  } else if (tempFileType === 'jpeg' || tempFileType === 'jpg' || tempFileType === 'png') {
    fileType = 'image'
  } else if (tempFileType === 'pdf') {
    fileType = 'pdf'
  }
  return fileType
}

export const prescriptionOrderStateObj = {
  received: {
    action: 'Awaiting for a Pharmacist',
    message: 'Thank you for placing order with your prescription. Our pharmacist shall soon review your prescription!',
  }, 
  picked: {
    action: 'Picked up by a Pharmacist', 
    message: 'Our pharmcist is currently reviewing your prescription'
  },
  placed: {
    action: 'Awaiting for your confirmation',
    message: 'Please review the products and the cost and confirm your order'
  }
}

const nowDate = new Date()
export const minExpirationDate = nowDate.setDate(nowDate.getDate() + 16) 
  
export const orderActivityObj = {
  received: {
    location: 10,
    color: darkBlue,
    client: {
      action: 'Awaiting for a Pharmacist',
      message: 'Thank you for placing order with your prescription. Our pharmacist shall soon review your prescription!'
    },
    operator: {
      action: 'Awaiting for your action',
      message: 'Accept the order and place the order on the received prescription'
    }
  },
  picked: {
    location: 20,
    color: darkBlue,
    client: {
      action: 'Picked up by a Pharmacist',
      message: 'Our pharmcist is currently reviewing your prescription'
    },
    operator: {
      action: 'Order placement',
      message: ''
    }
  },
  intial_user_confirmation: {
    location: 30,
    color: darkBlue,
    client: {
      action: 'Awaiting for your confirmation',
      message: 'Please review the products and the cost and confirm your order'
    },
    operator: {
      action: 'Awaiting for user confirmation',
      message: ''
    }
  },
  placed: {
    location: 40,
    color: orange,
    client: {
      action: '',
      message: ''
    },
    operator: {
      action: '',
      message: ''
    }
  },
  at_store: {
    location: 60,
    color: orange,
    client: {
      action: 'Awaiting for store',
      message: ''
    },
    operator: {
      action: 'Awaiting for store',
      message: 'Store is either processing the order or validating the insurance'
    }
  },
  final_user_confirmation: {
    location: 70,
    color: orange,
    client: {
      action: '',
      message: ''
    },
    operator: {
      action: 'Awaiting for customer',
      message: ''
    }
  },
  at_store_final: {
    location: 80,
    color: orange,
    client: {
      action: 'Awaiting for store',
      message: ''
    },
    operator: {
      action: 'Awaiting for store',
      message: 'Store is processing the order'
    }
  },
  delivering: {
    location: 90,
    color: blue,
    client: {
      action: '',
      message: ''
    },
    operator: {
      action: '',
      message: ''
    }
  },
  delivered: {
    location: 100,
    color: green,
    client: {
      action: '',
      message: ''
    },
    operator: {
      action: '',
      message: ''
    }
  },
  canceled: {
    color: red,
    client: {
      action: '',
      message: ''
    },
    operator: {
      action: 'Store canceled order',
      message: ''
    }
  },
  returned: {
    color: red,
    client: {
      action: '',
      message: ''
    },
    operator: {
      action: '',
      message: ''
    }
  },
  stuck: {
    color: strongRed,
    client: {
      action: '',
      message: ''
    },
    operator: {
      action: '',
      message: ''
    }
  },
}

export const logsObj = {
  login: {
    operator: {
      status: 'Unavailable'
    }
  },
  working: {
    operator: {
      status: 'Uvailable'
    }
  },
  logout: {
    operator: {
      status: 'Unavailable'
    }
  },
  unavailable: {
    operator: {
      status: 'Unavailable'
    }
  }
}

export const timestampsOfExtractedDayMonthYear = (date) => {
  const dateObject = new Date(date)

  const day = dateObject.getDate()
  const month = dateObject.getMonth() + 1 // Months are zero-based, so add 1
  const year = dateObject.getFullYear()

  const newDateObject = new Date(`${month}/${day}/${year} 00:00:00`)
  const timestamp = newDateObject.getTime()

  return timestamp
}