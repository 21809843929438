import apiClient from '../apiClient'

const uploadFileToCloudinary = async (obj, token) => {
    const {
        file, 
        endPoint
    } = obj

    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const fileData = new FormData()
    fileData.append('file', file)

    const response = await apiClient.post(endPoint, fileData, config)

    const data = response.data

    return data
}

const cloudinaryService = {
    uploadFileToCloudinary
}

export default cloudinaryService