import apiClient from '../apiClient'

const API_URL_SUPPLIERS_RELATIONS = '/api/v1/suppliers_with_listings'

// Get all suppliers with supplier listings
const getSupplierWithListings = async (supplierId) => {
    const response = await apiClient.get(`${API_URL_SUPPLIERS_RELATIONS}/${supplierId}`)

    const data = response.data

    return data
}

// Get a supplier with supplier listings
const getAllSuppliersWithListings = async () => {
    const response = await apiClient.get(API_URL_SUPPLIERS_RELATIONS)

    const data = response.data

    return data
}

const suppliersWithListingsService = {
    getSupplierWithListings,
    getAllSuppliersWithListings,
}
export default suppliersWithListingsService