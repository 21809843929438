import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { 
  addCart,
  resetCart,
} from '../../features/cart/cartSlice'
import { 
  addCheckout,
  resetCheckout,
} from '../../features/checkout/checkoutSlice'
import { 
  getPublicProduct,
  resetProductsWithStoreListings,
} from '../../features/productsWithStoreListings/productsWithStoreListingsSlice'
import AddInfoForm from '../../components/cart/AddInfoForm'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMStoreOption from '../../components/common/layout/HMStoreOption'
import HMLoginModal from '../../components/common/layout/HMLoginModal'
import HMProduct from '../../components/common/layout/HMProduct'
import HMModal from '../../components/common/layout/HMModal'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMButton from '../../components/common/tools/HMButton'
import HMSpiner from '../../components/common/tools/HMSpiner'
import HMAlert from '../../components/common/tools/HMAlert'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {
  currencies,
  storeDelivery,
  getStockAvailability,
  MAX_PURCHASE_PER_UNIT
} from '../../hooks/helperFunctions'
import {
  fontSize,
  textStyle
} from '../../components/common/tools/Styles'
import {
  gray,
  blue,
  white,
  orange,
  lightBlue,
  lightBlack,
  lightGreen,
  getRandomColor,
  seeThroughLightBlue,
} from '../../hooks/useColors'

const currency = currencies.find(curr => curr.country === 'Rwanda').currency

function PublicProduct() {
  const { productId } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { 
    user 
  } = useSelector((state) => state.auth)
  const cartState = useSelector((state) => state.cart)
  const { 
    isLoading,
    publicProduct,
    isPublicProduct,
  } = useSelector((state) => state.productsWithStoreListings)
  const checkoutState = useSelector((state) => state.checkout)

  const {
    windowW,
    windowH,
  } = useWindowDimensions()

  const isTabletScreen = windowW < 600 ? true : false
  const isBigHeightScreen = windowH < 1000 ? true : false
  const isNormalHeightScreen = windowH < 650 ? true : false
  const isSmallHeightScreen = windowH < 400 ? true : false
  const isVerySmallHeightScreen = windowH < 300 ? true : false

  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [isLogin, setIsLogin] = useState(false)
  const [isAddInfo, setIsAddInfo] = useState(false)
  const [selectedStore, setSelectedStore] = useState({})
  const [classification, setClassification] = useState('')
  const [selectedListing, setSelectedListing] = useState({})
  const [checkoutDetails, setCheckoutDetails] = useState([])
  const [storeLogoColors, setStoreLogoColors] = useState([])
  const [selectedListingId, setSelectedListingId] = useState('')
  const [recommendedStoreId, setRecommendedStoreId] = useState('')
  const [tempSelectedStoreId, setTempSelectedStoreId] = useState('')
  const [prescriptionFileUrl, setPrescriptionFileUrl] = useState('')
  const [formData, setFormData] = useState({
    other: '',
    patientAge: 16,
    firstTimeUse: true,
    hasPrescription: false,
    causedConditions: false,
    isPatientPregnant: false,
    preExistingConditions: 'None',
    prescription: "",
  })
  const {
    other,
    patientAge,
    prescription,
    firstTimeUse,
    hasPrescription,
    causedConditions,
    isPatientPregnant,
    preExistingConditions
  } = formData

  const breadOptions = [
    { text: 'Home', link: '/landing' },
    { text: 'Categories', link: '/categories' },
    { text: Object.keys(publicProduct).length ? `${publicProduct.product.category.id.parent.name}` : '.', 
      link: Object.keys(publicProduct).length && `/categories/${publicProduct.product.category.id.parent._id}` 
    },
    { text: Object.keys(publicProduct).length ? `${publicProduct.product.category.id.name}` : '.', 
      link: Object.keys(publicProduct).length && `/categories/${publicProduct.product.category.id.parent._id}/${publicProduct.product.category.id._id}`
    },
    { text: Object.keys(publicProduct).length ? `${publicProduct.product.name}` : '.', 
      link: null 
    }
  ]

  useEffect(() => {
    dispatch(getPublicProduct(productId)) 
    // eslint-disable-next-line
  }, [productId])

  useEffect(() => {
    if (Object.keys(publicProduct).length) {
      setSelectedStore(publicProduct.storesAndListings[0].store)
      setRecommendedStoreId(publicProduct.storesAndListings[0].store._id)
      setSelectedListing(publicProduct.storesAndListings[0].storeListing)
      setTempSelectedStoreId(publicProduct.storesAndListings[0].store._id)
      setSelectedListingId(publicProduct.storesAndListings[0].storeListing._id)
      setClassification(publicProduct.product.category.id.parent.name.toLowerCase())

      let colors = []
      publicProduct.storesAndListings.forEach(() => {
        colors.push(getRandomColor())
      })
      setStoreLogoColors(colors)
    }
    // eslint-disable-next-line
  }, [publicProduct])

  useEffect(() => {
    if (isPublicProduct) {
      dispatch(resetProductsWithStoreListings())
    }

    if (cartState.isAdded) {
      dispatch(resetCart())
    }

    if (checkoutState.isAdded) {
      dispatch(resetCheckout())
      navigate('/checkout')
    }

    if (Object.keys(selectedListing).length) {
      const availableStore = getStockAvailability(selectedListing.availableStock, selectedListing.transitStockCount)
      if (availableStore.stockCount <= quantity) {
        setQuantity(
          quantity >= MAX_PURCHASE_PER_UNIT && MAX_PURCHASE_PER_UNIT < availableStore.stockCount
          ? MAX_PURCHASE_PER_UNIT
          : availableStore.stockCount
        )
      } else if (quantity > MAX_PURCHASE_PER_UNIT) {
        setQuantity(MAX_PURCHASE_PER_UNIT)
      }
    }
    // eslint-disable-next-line
  }, [isPublicProduct, cartState , selectedListing, checkoutState, quantity])

  useEffect(() =>{
    setFormData((prevState) => ({
      ...prevState,
      prescription: prescriptionFileUrl,
    }))
  }, [prescriptionFileUrl])

  const navegateToStorePage = (e, storeId) => {
    e.stopPropagation()
    navigate(`/stores/${storeId}`)
  }
 
  const handleChangeStoreModal = (e) => {
    e.stopPropagation()
    setTitle('Store Selection')
    setOpen(true)
  }

  const handleChangeQuantity = (e) => {
    const value = e.target.value
    if (value > 0) {
      setQuantity(value)
    }
  }

  const handleQuantityReduce = () => {
    const value = parseInt(quantity)
    if (value > 1) {
      setQuantity(value - 1)
    }
  }
  const handleQuantityIncrease = (stockCount) => {
    const value = parseInt(quantity)
    if (value < stockCount) {
      setQuantity(value + 1)
    }
  }

  const handleSelectStore = (listingId) => {
    setSelectedListingId(listingId)
    const tempData = publicProduct.storesAndListings.find(data => data.storeListing._id === listingId)
    setTempSelectedStoreId(tempData.store._id)
  }
  const handleSelectedListingId = (e) => {
    e.stopPropagation()
    const value = e.target.value
    setSelectedListingId(value)
    const tempData = publicProduct.storesAndListings.find(data => data.storeListing._id === value)
    setTempSelectedStoreId(tempData.store._id)
  }
  const handleSelectedListing = () => {
    const tempData = publicProduct.storesAndListings.find(data => data.storeListing._id === selectedListingId)
    setSelectedStore(tempData.store)
    setSelectedListing(tempData.storeListing)
    handleClose()
  }

  const handleAddInfoModal = () => {
    setOpen(true)
    setIsAddInfo(true)
    setTitle(
      classification.includes('prescription') ? 'Upload Prescription' 
      : classification.includes('otc') && 'Additional Information'
    )
  }

  const handleClose = () => {
    setTitle('')
    setOpen(false)
    setIsAddInfo(false)
    setPrescriptionFileUrl("")
  }

  const handleChange = (e) => {
    let value = e.target.value
    const name = e.target.name

    if (name === 'patientAge' && (value > 130 || value < 0) ) {
      value = patientAge
    } else if (name === 'firstTimeUse') {
      value = (value === true || value === 'true') ? true : false
    } else if (name === 'hasPrescription') {
      value = (value === true || value === 'true') ? true : false
    } else if (name === 'causedConditions') {
      value = (value === true || value === 'true') ? true : false
    } else if (name === 'isPatientPregnant') {
      value = (value === true || value === 'true') ? true : false
    }

    setFormData((prevState) => ({
        ...prevState,
        [name]: value,
    }))
  }

  const handleAddProductToCart = () => {
    if (user) {
      const cartItem = {
        quantity: parseInt(quantity),
        storeListing: selectedListing._id,
      }
      dispatch(addCart({cartItem}))
    } else {
      setIsLogin(true)
    }
  }
  const handleBuyNow = () => {
    if (user) {
      const checkoutItem = {
        quantity,
        select: false,
        classification,
        addInfoSelect: false,
        listing: selectedListing._id,
        product: publicProduct.product._id,
        stockCount: selectedListing.stockCount,
        productName: publicProduct.product.name,
        onlinePrice: selectedListing.onlinePrice,
        productImage: publicProduct.product.image,
        categoryId: publicProduct.product.category.id._id,
        insurancePrice: publicProduct.product.insurancePrice,
        needsPrescription: publicProduct.product.needsPrescription,
        categoryParentId: publicProduct.product.category.id.parent_id,
        category: publicProduct.product.category.id.name.toLowerCase(),
      }
      const tempCheckoutDetails = [
        {
          quantity,
          select: false,
          store: selectedStore._id,
          storeName: selectedStore.name,
          storeType: selectedStore.type,
          checkoutItems: [ checkoutItem ],
          deliveryOption: storeDelivery[0],
          storePhone: `0${selectedStore.phone[0]}`,
          subtotal: (quantity * selectedListing.onlinePrice) + storeDelivery[0].cost,
        }
      ]
  
      setCheckoutDetails(tempCheckoutDetails)
  
      if (classification.includes('prescription')) {
        handleAddInfoModal()
      } else if (classification.includes('otc')) {
        handleAddInfoModal()
      } else if (classification.includes('health')) {
        dispatch(addCheckout({ 
          isFastBuy: true, 
          checkoutDetails: tempCheckoutDetails 
        }))
      }
    } else {
        setIsLogin(true)
    }
  }

  const handleProceedToCheckout = () => {
    const tempAddedInfo = {
      patientAge,
      firstTimeUse,
      causedConditions,
      isPatientPregnant,
      preExistingConditions,
      uploadedPrescription: hasPrescription
    }

    if (classification.includes('prescription')) {
      const addedInfo = {
        prescription,
        uploadedPrescription: true,
      } 

      const tempCheckoutDetails = checkoutDetails.map(checkout => ({
        ...checkout,
        checkoutItems: checkout.checkoutItems.map(item => ({
          ...item,
          addedInfo
        }))
      }))

      dispatch(addCheckout({ 
        isFastBuy: true, 
        checkoutDetails: tempCheckoutDetails 
      }))
    } else if (classification.includes('otc')) {
      const addedInfo = hasPrescription ? {
        prescription,
        uploadedPrescription: hasPrescription,
      } : preExistingConditions === 'Other' ? {
        ...tempAddedInfo,
        other
      } : tempAddedInfo

      const tempCheckoutDetails = checkoutDetails.map(checkout => ({
        ...checkout,
        checkoutItems: checkout.checkoutItems.map(item => ({
          ...item,
          addedInfo
        }))
      }))
      
      dispatch(addCheckout({ 
        isFastBuy: true, 
        checkoutDetails: tempCheckoutDetails 
      }))
    } 

    handleClose()
  }

  const isDisabled = (
    (patientAge > 130 || patientAge < 0) ||
    (preExistingConditions === 'Other' && other.length <= 4) ||
    (
      ((classification.includes('otc') && hasPrescription) || classification.includes('prescription')) && 
      (!prescription.length || !prescriptionFileUrl.length)
    )  
  ) ? true : false

  const handleCloseLoginModal = () => {
    setIsLogin(false)
  }

  return (
    <Page>
      {!user && isLogin ? (
        <HMLoginModal 
          open={isLogin}
          handleClose={handleCloseLoginModal}
        />
      ) : <></>}
      {(
        isLoading || 
        checkoutState.isLoading || 
        !Object.keys(publicProduct).length || 
        !Object.keys(selectedListing).length
      ) ? (
        <HMSpiner 
          size={60}
          zIndex={999}
          width='100%'
          height='80vh'
          margin='auto'
          position='absolute'
          bgColor='transparent'
        />
      ) : <></>}
      <FullWidthContainer
        display='flex'
      >
        {(() => {
          if (isTabletScreen) {
            breadOptions.splice(1, 3)
          }
          return (
            <HMBreadcrumbs 
              options={breadOptions}
              margin='auto auto auto 0'
            />
          )
        })()}
      </FullWidthContainer>
      <HMModal 
        open={open} 
        title={title}
        maxWidth='600px'
        maxHeight='95vh'
        handleClose={handleClose} 
        colors={{lightBlack, lightBlue}}
      >
        {isAddInfo ? (
          <HMBox
            width='100%'
            display='flex'
            margin='20px 0 10px 0'
            flexDirection='column'
            className='HM-cart-info'
            minWidth={isTabletScreen ? '80vw' : '500px'}
          >
            <AddInfoForm 
              formData={formData}
              handleChange={handleChange}
              classification={classification}
              prescriptionFileUrl = {prescriptionFileUrl}
              setPrescriptionFileUrl = {setPrescriptionFileUrl}
            />
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='15px auto 0 auto'
              className='change-store-button fade-in'
            >
              <HMButton 
                type='button'
                width='100px'
                bgColor={gray}
                fontWeight={500}
                margin='auto 10px auto 0'
                handleClick={handleClose}
                text={<Typography sx={textStyle}>Cancel</Typography>}
              />
              <HMButton 
                type='button'
                width='180px'
                color={white}
                bgColor={orange}
                disabled={isDisabled}
                margin='auto 0 auto auto'
                handleClick={handleProceedToCheckout}
                text={
                  <Typography sx={textStyle}>
                    Proceed to checkout
                  </Typography>
                }
              />
            </HMBox>
          </HMBox>
        ) : (
          <>
            <HMAlert 
              warning={true}
              margin="10px auto"
              textAlign="justify"
              fontSize={fontSize - 3}
              text="Based on your location, we've recommended a store to fulfill your order. 
              Ordering from places further away from you, or from multiple stores will increase the delivery fee. 
              We're here to help you save money."
            />
            <HMBox 
              padding='0'
              width='100%'
              overflowY='auto'
              className='fade-in'
              maxHeight={
                isVerySmallHeightScreen ? '30vh' 
                : isSmallHeightScreen ? '40vh' 
                : isNormalHeightScreen ? '50vh' 
                : isBigHeightScreen ? '60vh' 
                : '70vh'
              }
            >
              {Object.keys(selectedListing).length && publicProduct.storesAndListings.map((data, index) => (
                <HMStoreOption 
                  key={index}
                  cursor='pointer'
                  store={data.store}
                  currency={currency}
                  value={data.storeListing._id}
                  border={`1px solid ${lightBlue}`}
                  hoverBorder={`1px solid ${blue}`}
                  cost={data.storeListing.onlinePrice}
                  selectedStoreId={tempSelectedStoreId}
                  recommendedStoreId={recommendedStoreId}
                  storeLogoColor={storeLogoColors[index]}
                  handleRadioButtonChange={handleSelectedListingId}
                  handleStore={(e) => navegateToStorePage(e, data.store._id)}
                  handleClick={() => handleSelectStore(data.storeListing._id)}
                  bgColor={recommendedStoreId === data.store._id ? lightGreen : seeThroughLightBlue}
                />
              ))}
            </HMBox>
            <HMBox
              padding='0'
              width='100%'
              display='flex'
              margin='15px auto 0 auto'
              className='change-store-button fade-in'
            >
              <HMButton 
                type='button'
                width='100px'
                bgColor={gray}
                fontWeight={500}
                margin='auto 10px auto 0'
                handleClick={handleClose}
                text={<Typography sx={textStyle}>Cancel</Typography>}
              />
              <HMButton 
                type='button'
                width='100px'
                bgColor={blue}
                margin='auto 0 auto auto'
                handleClick={handleSelectedListing}
                text={<Typography sx={textStyle}>Change</Typography>}
              />
            </HMBox>
          </>
        )}
      </HMModal>
      <Section
        padding={10}
        bgColor={white}
      >
        {(
          Object.keys(publicProduct).length && 
          Object.keys(selectedStore).length && 
          Object.keys(selectedListing).length
        )  ? (
          <HMProduct
            quantity={quantity}
            store={selectedStore}
            listing={selectedListing}
            handleBuyNow={handleBuyNow}
            product={publicProduct.product}
            handleQuantityReduce={handleQuantityReduce} 
            handleChangeQuantity={handleChangeQuantity}
            handleAddProductToCart={handleAddProductToCart}
            handleChangeStoreModal={handleChangeStoreModal} 
            navegateToStorePage={(e) => navegateToStorePage(e, selectedStore._id)}
            canChangeStore={publicProduct.storesAndListings.length > 1 ? true : false}
            handleQuantityIncrease={(stockCount) => handleQuantityIncrease(stockCount)} 
          />
        ) : <></>}
      </Section>
    </Page>
  )
}

export default PublicProduct