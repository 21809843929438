import apiClient from '../apiClient'

const API_URL_STORE_LISTINGS = '/api/v1/storeListings'

// Get all storeListings for public use
const getPublicStoreListings = async () => {

    const response = await apiClient.get(`${API_URL_STORE_LISTINGS}/public`)

    const data = response.data

    return data
}

// Get a storeListing for public use
const getPublicStoreListing = async (listingId) => {

    const response = await apiClient.get(`${API_URL_STORE_LISTINGS}/public/${listingId}`)

    const data = response.data

    return data
}

// Get all storeListings
const getStoreListings = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await apiClient.get(`${API_URL_STORE_LISTINGS}/private`, config)

    const data = response.data

    return data
}

// Get a storeListing
const getStoreListing = async (listingId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await apiClient.get(`${API_URL_STORE_LISTINGS}/private/${listingId}`, config)

    const data = response.data

    return data
}

// Create a new storeListing
const createStoreListing = async (newStoreListing, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.post(`${API_URL_STORE_LISTINGS}/private`, newStoreListing, config)

    const data = response.data

    return data
}

// Update a storeListing
const updateStoreListing = async (storeListing, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const storeListingId = storeListing._id
    delete storeListing['_id']
    const response = await apiClient.post(`${API_URL_STORE_LISTINGS}/private/${storeListingId}`, storeListing, config)

    const data = response.data

    return data
}

// Update a storeListing
const deleteStoreListing = async (storeListingID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.delete(`${API_URL_STORE_LISTINGS}/private/${storeListingID}`, config)

    const data = response.data

    return data
}

const storeListingService = {
    getStoreListing,
    getStoreListings,
    createStoreListing,
    updateStoreListing,
    deleteStoreListing,
    getPublicStoreListing,
    getPublicStoreListings,
}
export default storeListingService