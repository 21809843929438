import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import NotificationImportantTwoToneIcon from '@mui/icons-material/NotificationImportantTwoTone'
import { 
  bellStyle,
  countStyle,
  circleStyle,
  notificationSytle,
} from './Styles'

function HMNotification() {
  return (
    <Grid sx={notificationSytle}>
      <NotificationImportantTwoToneIcon sx={bellStyle} />
      <Grid sx={circleStyle}>
        <Typography variant='body2' component='span' sx={countStyle}>3</Typography>
      </Grid>
    </Grid>
  )
}

export default HMNotification