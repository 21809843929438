import apiClient from '../apiClient'
const API_URL_LOGS = '/api/v1/logs'

// A function that creates a log 
const addLog = async (log, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await apiClient.post(API_URL_LOGS, log, config)

    const data = response.data

    return data 
}

// A function that gets all logs
const getLogs = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await apiClient.get(API_URL_LOGS, config)

    const data = response.data

    return data 
}

// A function that gets all user's logs
const getUserLogs = async (userId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await apiClient.get(`${API_URL_LOGS}/${userId}`, config)

    const data = response.data

    return data 
}

// A function that gets all my logs
const getMyLogs = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await apiClient.get(`${API_URL_LOGS}/me`, config)

    const data = response.data

    return data 
}

// A function that gets uer's latest log
const getUserLatestLog = async (userId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await apiClient.get(`${API_URL_LOGS}/latest/${userId}`, config)

    const data = response.data

    return data 
}

// A function that gets my latest log
const getMyLatestLog = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await apiClient.get(`${API_URL_LOGS}/latest/me`, config)

    const data = response.data

    return data 
}


const logService = {
    addLog,
    getLogs,
    getMyLogs,
    getUserLogs,
    getMyLatestLog,
    getUserLatestLog,
}

export default logService

