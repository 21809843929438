import apiClient from '../apiClient'

const API_URL_SUPPLIER_LISTINGS = '/api/v1/supplierListings'

// Get all supplierListings
const getSupplierListings = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await apiClient.get(API_URL_SUPPLIER_LISTINGS, config)

    const data = response.data

    return data
}

// Get a supplierListing
const getSupplierListing = async (listingId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await apiClient.get(`${API_URL_SUPPLIER_LISTINGS}/${listingId}`, config)

    const data = response.data

    return data
}

// Create a new supplierListing
const createSupplierListing = async (newSupplierListing, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.post(API_URL_SUPPLIER_LISTINGS, newSupplierListing, config)

    return response.data
}

// Update a supplierListing
const updateSupplierListing = async (supplierListing, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const supplierListingId = supplierListing._id
    delete supplierListing['_id']
    const response = await apiClient.post(`${API_URL_SUPPLIER_LISTINGS}/${supplierListingId}`, supplierListing, config)

    return response.data
}

// Update a supplierListing
const deleteSupplierListing = async (supplierListingID, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await apiClient.delete(`${API_URL_SUPPLIER_LISTINGS}/${supplierListingID}`, config)

    return response.data
}

const supplierListingService = {
    getSupplierListing,
    getSupplierListings,
    createSupplierListing,
    updateSupplierListing,
    deleteSupplierListing,
}
export default supplierListingService