import React from 'react'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Radio from '@mui/material/Radio'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import HMBox from './HMBox'
import HMText from '../tools/HMText'
import Title from '../tools/Title'
import {
    fontSize,
    iconStyle
} from '../tools/Styles'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import {
    red,
    blue,
    white,
    orange,
    darkBlue,
    getRandomColor
} from '../../../hooks/useColors'

function HMStoreOption(props) {
    const {
        windowW,
    } = useWindowDimensions()

    const isTabletScreen = windowW < 600 ? true : false

    return (
        <HMBox
            width='100%'
            display='flex'
            minWidth='400px'
            margin='5px auto'
            className='fade-in'
            border={props.border}
            bgColor={props.bgColor}
            handleClick={props.handleClick}
            hoverBorder={props.hoverBorder}
            cursor={(props.isOperator || props.cursor) && 'pointer'} 
        >
            <HMBox
                padding='0'
                display='flex'
                margin='auto 0'
            >
            {props.store.logo ? (
                <Link 
                    sx={{ margin: 'auto 0' }}
                    onClick={props.handleStore}  
                >
                    <img
                        src={props.store.logo}
                        alt={props.store.name}
                        className='mini-image'
                        style={{ margin: 'auto', cursor: 'pointer' }}
                    />
                </Link>
            ) : (
                <Link
                    onClick={props.handleStore}  
                    sx={{ color: darkBlue, margin: 'auto 0', cursor: 'pointer', textDecoration: 'none'}}
                >
                    <Grid 
                        sx={{
                            width: '40px',
                            height: '40px',
                            margin: 'auto',
                            display: 'flex',
                            borderRadius: '50%',
                            backgroundColor: props.storeLogoColor,
                        }}
                    >
                        <Title
                            margin='auto'
                            color={white}
                            textAlign='center'
                            size={fontSize + 14}
                            title={props.store.name.substr(0, 1).toUpperCase()}
                        />
                    </Grid>
                </Link>
            )}
            <HMBox
                width='100%'
                display='flex'
                flexDirection='column'
                margin='auto auto auto 5px'
            >
                <Link 
                    onClick={props.handleStore}  
                    sx={{ color: darkBlue, cursor: 'pointer', width: 'max-content' }}
                >
                    <Title
                        textAlign='left'
                        size={fontSize - 2}
                        title={props.store.name}
                        margin='auto auto 2.5px auto'
                    />
                </Link>
                <HMBox
                    padding='0'
                    margin='auto'
                    display='flex'
                >
                    <HMText
                        margin='auto'
                        padding='0 5px'
                        bgColor={white}
                        borderRadius={5}
                        text={props.store.type}
                        fontSize={`${fontSize - 1}px`}
                    />
                    &nbsp; | &nbsp;
                    <LocationOnOutlinedIcon sx={{...iconStyle, color: red}} />
                    <HMText
                        margin='auto 0'
                        fontSize={`${fontSize - 1}px`}
                        text={props.store.address.sector}
                    />
                    {!isTabletScreen ? (
                        <>
                        &nbsp; - &nbsp;
                        <HMText
                            margin='auto 0'
                            fontSize={`${fontSize - 1}px`}
                            text={props.store.address.district}
                        />
                        </>
                    ) : <></>}
                </HMBox>
            </HMBox>
            </HMBox>
            <HMBox
                display='flex'
                margin='auto 0 auto auto'
            >
            <HMBox 
                display='flex'
                flexDirection='column'
            >
                <HMText 
                    width='100%'
                    margin='auto'
                    color={orange}
                    minWidth='80px'
                    fontWeight={500}
                    fontSize={`${fontSize - 2}px`}
                    textAlign={props.isOperator ? 'right' : 'left'}
                    text={`${props.currency} ${props.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                />
                <HMBox 
                    padding='0'
                    display='flex'
                    margin='auto auto 0 auto'
                >
                    {props.recommendedStoreId === props.store._id ? (
                        <HMText
                            color={blue}
                            margin='auto auto 0 auto'
                            fontSize={`${fontSize - 3}px`}
                            text={`Recommended ${props.isOperator ? '&nbsp; | &nbsp;' : ''}`}
                        />
                    ) : <></>}
                    {props.isOperator ? (
                        <HMText
                            color={blue}
                            text={props.operatorMsg}
                            margin='auto auto 0 auto'
                            fontSize={`${fontSize - 3}px`}
                        />
                    ) : <></>}
                </HMBox>
            </HMBox>
            <Radio
                size='small'
                value={props.value}
                name='radio-buttons'
                inputProps={{ 'aria-label': 'A' }}
                onChange={props.handleRadioButtonChange}
                checked={props.selectedStoreId === props.store._id}
                className={`listing-radio-button ${props.selectedStoreId === props.store._id && 'checked'}`}
                sx={{
                    margin: 'auto 0 auto auto',
                }}
            />
            </HMBox>
        </HMBox>
    )
}

export default HMStoreOption