import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone'
import { 
    addCart,
    resetCart,
} from '../../features/cart/cartSlice'
import {  
    resetStoreListing,
    getPublicStoreListing,
} from '../../features/storeListings/storeListingSlice'
import { 
    addCheckout,
    resetCheckout,
} from '../../features/checkout/checkoutSlice'
import AddInfoForm from '../../components/cart/AddInfoForm'
import HMBreadcrumbs from '../../components/common/navigation/HMBreadcrumbs'
import FullWidthContainer from '../../components/common/layout/FullWidthContainer'
import HMLoginModal from '../../components/common/layout/HMLoginModal'
import HMProduct from '../../components/common/layout/HMProduct'
import HMModal from '../../components/common/layout/HMModal'
import Section from '../../components/common/layout/Section'
import HMBox from '../../components/common/layout/HMBox'
import Page from '../../components/common/layout/Page'
import HMButton from '../../components/common/tools/HMButton'
import HMSpiner from '../../components/common/tools/HMSpiner'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import {
    storeDelivery,
    getStockAvailability,
    MAX_PURCHASE_PER_UNIT
} from '../../hooks/helperFunctions'
import {
    iconStyle,
    textStyle
} from '../../components/common/tools/Styles'
import {
    gray,
    white,
    orange,
    lightBlue,
    lightBlack,
} from '../../hooks/useColors'

function PublicStoreProduct() {
    const { listingId } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { 
        user 
    } = useSelector((state) => state.auth)
    const { 
        isError,
        isLoading,
        publicStoreListing,
        isPublicStoreListing,
    } = useSelector((state) => state.storeListing)
    const cartState = useSelector((state) => state.cart)
    const checkoutState = useSelector((state) => state.checkout)

    const {
        windowW
    } = useWindowDimensions()

    const isTabletScreen = windowW < 600 ? true : false

    const [title, setTitle] = useState('')
    const [open, setOpen] = useState(false)
    const [quantity, setQuantity] = useState(1)
    const [isLogin, setIsLogin] = useState(false)
    const [classification, setClassification] = useState('')
    const [checkoutDetails, setCheckoutDetails] = useState([])
    const [prescriptionFileUrl, setPrescriptionFileUrl] = useState('')
    const [formData, setFormData] = useState({
        other: '',
        patientAge: 16,
        prescription: '',
        firstTimeUse: true,
        hasPrescription: false,
        causedConditions: false,
        isPatientPregnant: false,
        preExistingConditions: 'None',
    })
    const {
        other,
        patientAge,
        prescription,
        firstTimeUse,
        hasPrescription,
        causedConditions,
        isPatientPregnant,
        preExistingConditions
    } = formData

    const breadOptions = [
        { text: 'Home', link: '/landing' },
        { text: 'Stores', link: '/stores' },
        { 
            text: Object.keys(publicStoreListing).length ? `${publicStoreListing.store.name}` : '.', 
            link: Object.keys(publicStoreListing).length && `/stores/${publicStoreListing.store._id}` 
        },
        { text: Object.keys(publicStoreListing).length ? `${publicStoreListing.product.name}` : '.', link: null },
    ]

    useEffect(() => {
        dispatch(getPublicStoreListing(listingId))
        // eslint-disable-next-line
    }, [listingId])

    useEffect(() => {
        if (isPublicStoreListing) {
          dispatch(resetStoreListing())
        } else if (isError) {
            dispatch(resetStoreListing())
            navigate('/*')
        }
    
        if (cartState.isAdded) {
          dispatch(resetCart())
        }

        if (checkoutState.isAdded) {
            dispatch(resetCheckout())
            navigate('/checkout')
        }

        if (Object.keys(publicStoreListing).length) {
            setClassification(publicStoreListing.product.category.id.parent.name.toLowerCase())

            const availableStore = getStockAvailability(publicStoreListing.availableStock, publicStoreListing.transitStockCount)
            
            if (availableStore.stockCount <= parseInt(quantity)) {
                setQuantity(
                    parseInt(quantity) >= MAX_PURCHASE_PER_UNIT && MAX_PURCHASE_PER_UNIT < availableStore.stockCount
                    ? MAX_PURCHASE_PER_UNIT
                    : availableStore.stockCount
                )
            } else if (parseInt(quantity) > MAX_PURCHASE_PER_UNIT) {
                setQuantity(MAX_PURCHASE_PER_UNIT)
            }
        }
        // eslint-disable-next-line
    }, [isError, cartState, checkoutState,  publicStoreListing, isPublicStoreListing, quantity])

    useEffect(() =>{
        setFormData((prevState) => ({
            ...prevState,
            prescription: prescriptionFileUrl,
        }))
        // eslint-disable-next-line
    }, [prescriptionFileUrl])

    const navegateToStorePage = (storeId) => {
        navigate(`/stores/${storeId}`)
    }
    
    const handleChangeQuantity = (e) => {
        const value = e.target.value
        if (value >= 1) {
          setQuantity(value)
        }
    }
    
    const handleQuantityReduce = () => {
        const value = parseInt(quantity)
        if (value > 1) {
          setQuantity(value - 1)
        }
    }
    const handleQuantityIncrease = (stockCount) => {
        const value = parseInt(quantity)
        if (value < stockCount) {
          setQuantity(value + 1)
        }
    }
    const handleChange = (e) => {
        let value = e.target.value
        const name = e.target.name
    
        if (name === 'patientAge' && (value > 130 || value < 0) ) {
          value = patientAge
        } else if (name === 'firstTimeUse') {
            value = (value === true || value === 'true') ? true : false
        } else if (name === 'hasPrescription') {
            value = (value === true || value === 'true') ? true : false
        } else if (name === 'causedConditions') {
            value = (value === true || value === 'true') ? true : false
        } else if (name === 'isPatientPregnant') {
            value = (value === true || value === 'true') ? true : false
        }
    
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }

    const handleAddInfoModal = () => {
        setOpen(true)
        setTitle(
            classification.includes('prescription') 
            ? 'Upload Prescription' 
            : classification.includes('otc') && 'Additional Information'
        )
    }
    const handleClose = () => {
        setOpen(false)
        setTitle('')
    }

    const handleAddProductToCart = () => {
        if (user) {
            const cartItem = {
              quantity: parseInt(quantity),
              storeListing: publicStoreListing._id,
            }
            dispatch(addCart({cartItem}))
        } else {
            setIsLogin(true)
        }
    }
    const handleBuyNow = () => {
        if (user) {
            const checkoutItem = {
              quantity,
              select: false,
              addInfoSelect: false,
              classification: classification,
              listing: publicStoreListing._id,
              product: publicStoreListing.product._id,
              stockCount: publicStoreListing.stockCount,
              onlinePrice: publicStoreListing.onlinePrice,
              productName: publicStoreListing.product.name,
              productImage: publicStoreListing.product.image,
              categoryId: publicStoreListing.product.category.id._id,
              insurancePrice: publicStoreListing.product.insurancePrice,
              needsPrescription: publicStoreListing.product.needsPrescription,
              categoryParentId: publicStoreListing.product.category.id.parent_id,
              category: publicStoreListing.product.category.id.name.toLowerCase(),
            }
            const tempCheckoutDetails = [
              {
                quantity,
                select: false,
                checkoutItems: [ checkoutItem ],
                deliveryOption: storeDelivery[0],
                store: publicStoreListing.store._id,
                storeName: publicStoreListing.store.name,
                storeType: publicStoreListing.store.type,
                storePhone: `0${publicStoreListing.store.phone[0]}`,
                subtotal: (quantity * publicStoreListing.onlinePrice) + storeDelivery[0].cost,
              }
            ]
            
            setCheckoutDetails(tempCheckoutDetails)
        
            if (classification.includes('prescription')) {
              handleAddInfoModal()
            } else if (classification.includes('otc')) {
              handleAddInfoModal()
            } else if (classification.includes('health')) {
                dispatch(addCheckout({ 
                    isFastBuy: true, 
                    checkoutDetails: tempCheckoutDetails 
                  }))
            }
        } else {
            setIsLogin(true)
        }
    }
    
    const handleProceedToCheckout = () => {
        const tempAddedInfo = {
          patientAge,
          firstTimeUse,
          causedConditions,
          isPatientPregnant,
          preExistingConditions,
          uploadedPrescription: hasPrescription
        }
    
        
        if (classification.includes('prescription')) {
          const addedInfo = {
            prescription,
            uploadedPrescription: true,
          } 
    
          const tempCheckoutDetails = checkoutDetails.map(checkout => ({
            ...checkout,
            checkoutItems: checkout.checkoutItems.map(item => ({
              ...item,
              addedInfo
            }))
          }))
          
          dispatch(addCheckout({ 
            isFastBuy: true, 
            checkoutDetails: tempCheckoutDetails 
          }))
    
        } else if (classification.includes('otc')) {
          const addedInfo = hasPrescription ? {
            prescription,
            uploadedPrescription: hasPrescription,
          } : preExistingConditions === 'Other' ? {
            ...tempAddedInfo,
            other
          } : tempAddedInfo
    
          const tempCheckoutDetails = checkoutDetails.map(checkout => ({
            ...checkout,
            checkoutItems: checkout.checkoutItems.map(item => ({
              ...item,
              addedInfo
            }))
          }))
          
          dispatch(addCheckout({ 
            isFastBuy: true, 
            checkoutDetails: tempCheckoutDetails 
          }))
        } 
    
        handleClose()
    }

    const isDisabled = (
        (patientAge > 130 || patientAge < 0) ||
        (preExistingConditions === 'Other' && other.length <= 4) ||
        (
          ((classification.includes('otc') && hasPrescription) || classification.includes('prescription')) && 
          (!prescription.length || !prescriptionFileUrl.length)
        )  
    ) ? true : false

    const handleCloseLoginModal = () => {
        setIsLogin(false)
    }

    return (
        <Page>
            {!user && isLogin ? (
                <HMLoginModal 
                    open={isLogin}
                    handleClose={handleCloseLoginModal}
                />
            ) : <></>}
            {(
                isLoading || 
                checkoutState.isLoading || 
                !Object.keys(publicStoreListing).length
            ) ? (
                <HMSpiner 
                    size={60}
                    zIndex={999}
                    width='100%'
                    height='80vh'
                    margin='auto'
                    position='absolute'
                    bgColor='transparent'
                />
            ) : <></>}
            <FullWidthContainer
                display='flex'
            >
                {(() => {
                    if (isTabletScreen) {
                        breadOptions.splice(1, 2)
                    }
                    return (
                        <HMBreadcrumbs 
                            options={breadOptions}
                            margin='auto auto auto 0'
                        />
                    )
                })()}
                <HMBox 
                    padding='0'
                    float='right'
                    display='flex'
                >
                    <HMButton 
                        type='button'
                        bgColor={orange}
                        isResponsive={true}
                        margin='auto 0 auto auto'
                        handleClick={() => navigate(-1)}
                        text={<Typography sx={textStyle}>Back</Typography>} 
                        icon={<ArrowBackIosNewTwoToneIcon sx={iconStyle} />} 
                    />
                </HMBox>
            </FullWidthContainer>
            <HMModal 
                open={open} 
                title={title}
                maxWidth='600px'
                maxHeight='95vh'
                handleClose={handleClose} 
                colors={{lightBlack, lightBlue}}
            >
                <HMBox
                    width='100%'
                    display='flex'
                    margin='20px 0 10px 0'
                    flexDirection='column'
                    className='HM-cart-info'
                    minWidth={isTabletScreen ? '80vw' : '500px'}
                >
                    <AddInfoForm 
                        setPrescriptionFileUrl = {setPrescriptionFileUrl}
                        prescriptionFileUrl = {prescriptionFileUrl}
                        formData={formData}
                        handleChange={handleChange}
                        classification={classification}
                    />
                    <HMBox
                        padding='0'
                        width='100%'
                        display='flex'
                        margin='15px auto 0 auto'
                        className='change-store-button fade-in'
                    >
                        <HMButton 
                            type='button'
                            width='100px'
                            bgColor={gray}
                            fontWeight={500}
                            margin='auto 10px auto 0'
                            handleClick={handleClose}
                            text={<Typography sx={textStyle}>Cancel</Typography>}
                        />
                        <HMButton 
                            type='button'
                            width='180px'
                            color={white}
                            bgColor={orange}
                            disabled={isDisabled}
                            margin='auto 0 auto auto'
                            handleClick={handleProceedToCheckout}
                            text={
                            <Typography sx={textStyle}>
                                Proceed to checkout
                            </Typography>
                            }
                        />
                    </HMBox>
                </HMBox>
            </HMModal>
            <Section
                padding={10}
                bgColor={white}
            >
                {Object.keys(publicStoreListing).length ? (
                    <HMProduct
                        quantity={quantity}
                        handleBuyNow={handleBuyNow}
                        listing={publicStoreListing}
                        store={publicStoreListing.store}
                        product={publicStoreListing.product}
                        handleQuantityReduce={handleQuantityReduce} 
                        handleChangeQuantity={handleChangeQuantity}
                        handleAddProductToCart={handleAddProductToCart}
                        handleQuantityIncrease={(stockCount) => handleQuantityIncrease(stockCount)} 
                        navegateToStorePage={() => navegateToStorePage(publicStoreListing.store._id)}
                    />
                ) : <></>} 
            </Section>
        </Page>
    )
}

export default PublicStoreProduct