import apiClient from '../apiClient'

const API_URL_SEARCH = '/api/v1/extension/search'

// Search for stores
const searchFoundStores = async (obj) => {
    const { searchText, limit } = obj

    const body = {
        limit,
        q: searchText,
        tag: [ 'store' ]
    }

    const response = await apiClient.post(API_URL_SEARCH, body)

    const data = response.data

    return data
}

// Search for categories
const searchFoundCategories = async (obj) => {
    const { searchText, limit } = obj

    const body = {
        limit,
        q: searchText,
        tag: [ 'category' ]
    }

    const response = await apiClient.post(API_URL_SEARCH, body)

    const data = response.data

    return data
}

// Search for products available in store  
const searchProductsInStore = async (obj) => {
    const { searchText, storeId, limit } = obj

    const body = {
        limit,
        storeId,
        q: searchText,
        tag: [ 'productWithinStore' ],
    }

    const response = await apiClient.post(API_URL_SEARCH, body)

    const data = response.data

    return data.productWithinStore
}

// Search for products available overall  
const searchOverallProducts = async (obj) => {
    const { searchText, limit } = obj

    const body = {
        limit,
        q: searchText,
        tag: [ 'product' ]
    }

    const response = await apiClient.post(API_URL_SEARCH, body)

    const data = response.data

    return data.products
}

const searchService = {
    searchFoundStores,
    searchFoundCategories,
    searchProductsInStore,
    searchOverallProducts,
}

export default searchService