import React, { useEffect, useState } from 'react'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import RemoveIcon from '@mui/icons-material/Remove'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import HMBox from './HMBox'
import ProductDetail from '../tools/ProductDetail'
import HMTextField from '../tools/HMTextField'
import HMButton from '../tools/HMButton'
import HMAlert from '../tools/HMAlert'
import HMImage from '../tools/HMImage'
import HMText from '../tools/HMText'
import Title from '../tools/Title'
import {
    fontSize,
    iconStyle,
} from '../tools/Styles'
import {
    currencies,
    getStockAvailability,
    MAX_PURCHASE_PER_UNIT
} from '../../../hooks/helperFunctions'
import {
    red,
    blue,
    white,
    orange,
    darkBlue,
    getRandomColor,
    skeletonsLightBlue,
    seeThroughLightBlue,
} from '../../../hooks/useColors'
import useWindowDimensions from '../../../hooks/useWindowDimensions'

const currency = currencies.find(curr => curr.country === 'Rwanda').currency

function HMProduct(props) {
    const {
        name,
        image,
        brand,
        dosage, 
        howToUse,
        category,
        strength,
        sideEffects,
        description
    } = props.product
    const {
        onlinePrice,
        availableStock,
        transitStockCount,
    } = props.listing

    const {
        windowW
    } = useWindowDimensions()

    const isTabletScreen = windowW < 720 ? true : false
    const isHealth = category && category.id.parent.name.toLowerCase().includes('health') ? true : false

    const stockDetail = getStockAvailability(availableStock, transitStockCount)

    const [minLogoBgColor, setMiniLogoBgColor] = useState(getRandomColor())
    
    useEffect(() => {
        setMiniLogoBgColor(getRandomColor())
    }, [])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <HMImage 
                    alt={name}
                    src={image}
                    borderRadius='8px'
                    bgColor={skeletonsLightBlue}
                />
            </Grid>
            <Grid item xs={12} sm={6} sx={{display: 'flex', flexDirection: 'column'}}>
                <HMText 
                    text={brand}
                    width='100%'
                    fontWeight={500}
                    color={darkBlue}
                    className='fade-in'
                    fontSize={`${fontSize + 2}px`}
                />
                <HMText 
                    width='100%'
                    margin='5px 0'
                    color={orange}
                    fontWeight={500}
                    className='fade-in'
                    fontSize={`${fontSize + 2}px`}
                    text={`${currency} ${onlinePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
                />
                <HMText 
                    width='100%'
                    text='Store:'
                    margin='5px 0'
                    fontWeight={500}
                    color={darkBlue}
                    className='fade-in'
                    fontSize={`${fontSize}px`}
                />
                <HMBox
                    width='100%'
                    display='flex'
                    className='fade-in'
                    bgColor={seeThroughLightBlue}
                >
                    <HMBox
                        padding='0'
                        display='flex'
                        margin='auto 0'
                    >
                        {props.store.logo ? (
                            <Link 
                                sx={{ margin: 'auto 0', cursor: 'pointer' }}
                                onClick={props.navegateToStorePage} 
                            >
                                <img
                                    alt={props.store.name}
                                    src={props.store.logo}
                                    className='mini-image'
                                    style={{ margin: 'auto' }}
                                />
                            </Link>
                        ) : (
                            <Link 
                                onClick={props.navegateToStorePage} 
                                sx={{ color: darkBlue, margin: 'auto 0', textDecoration: 'none', cursor: 'pointer' }}
                            >
                                <Grid className='mini-logo'>
                                    <HMText
                                        float='none'
                                        width='100%'
                                        margin='auto'
                                        height='100%'
                                        color={white}
                                        fontWeight={700}
                                        textAlign='center'
                                        padding='0 10px 0 8px'
                                        bgColor={minLogoBgColor}
                                        text={props.store.name.substr(0, 1).toUpperCase()}
                                    />
                                </Grid>
                            </Link>
                        )}
                        <HMBox
                            width='100%'
                            margin='auto auto auto 5px'
                        >
                            <Link 
                                onClick={props.navegateToStorePage} 
                                sx={{ color: darkBlue, textDecoration: 'none', cursor: 'pointer' }}
                            >
                                <Title
                                    width='100%'
                                    textAlign='left'
                                    margin='2px 0 0 0'
                                    size={fontSize - 2}
                                    title={props.store.name}
                                />
                            </Link>
                            <HMBox
                                padding='0'
                                margin='auto'
                                display='flex'
                            >
                                <HMText
                                    margin='auto'
                                    padding='0 5px'
                                    bgColor={white}
                                    borderRadius={5}
                                    text={props.store.type}
                                    fontSize={`${fontSize - 1}px`}
                                />
                                &nbsp; | &nbsp;
                                <LocationOnOutlinedIcon sx={{...iconStyle, color: red}} />
                                <HMText
                                    margin='auto 0'
                                    fontSize={`${fontSize - 1}px`}
                                    text={props.store.address.sector}
                                />
                                &nbsp; - &nbsp;
                                <HMText
                                    margin='auto 0'
                                    fontSize={`${fontSize - 1}px`}
                                    text={props.store.address.district}
                                />
                            </HMBox>
                        </HMBox>
                    </HMBox>
                    {props.canChangeStore ? (
                        <HMBox
                            padding='0'
                            display='flex'
                            cursor='pointer'
                            className='fade-in'
                            margin='auto 0 auto auto'
                            handleClick={props.handleChangeStoreModal} 
                        >   
                            <HMText 
                                color={blue}
                                text='Change store'
                                width='max-content'
                                fontSize={`${fontSize}px`}
                                margin='10px 10px auto 10px'
                            />
                            <IconButton   
                                sx={{margin: 'auto 0 auto auto'}}
                                onClick={props.handleChangeStoreModal} 
                            >
                                <DriveFileRenameOutlineOutlinedIcon />
                            </IconButton>
                        </HMBox>
                    ) : <></>}
                </HMBox>
                {!isHealth ? (
                    <HMBox
                        padding='0'
                        width='100%'
                        display='flex'
                        flexDirection={isTabletScreen ? 'column' : 'row'}
                    >
                        <ProductDetail 
                            text={strength}
                            title='Strength'
                            margin={isTabletScreen ? '5px auto' : '5px 5px 5px auto'}
                        />
                        <ProductDetail 
                            text={dosage}
                            title='Dosage'
                            margin={isTabletScreen ? '5px auto' : '5px 5px 5px auto'}
                        />
                    </HMBox>
                ) : <></>}
                <HMText 
                    width='100%'
                    text='Quantity:'
                    fontWeight={500}
                    color={darkBlue}
                    className='fade-in'
                    margin='20px 0 5px 0'
                    fontSize={`${fontSize}px`}
                />
                <HMBox 
                    padding='5px 0'
                    display='flex'
                    className='fade-in'
                >
                    <IconButton   
                        sx={{margin: 'auto 0'}}
                        onClick={props.handleQuantityReduce} 
                        disabled={props.quantity === 1 ? true : false}
                    >
                        <RemoveIcon />
                    </IconButton>
                    <HMTextField 
                        width='70px'
                        type='number'
                        name='quantity'
                        margin='auto 5px'
                        value={props.quantity}
                        className='public-product quantity'
                        onChange={props.handleChangeQuantity}
                    />
                    <IconButton   
                        sx={{margin: 'auto 0'}}
                        onClick={() => props.handleQuantityIncrease(stockDetail.stockCount)} 
                        disabled={
                            stockDetail.stockCount === props.quantity || props.quantity === MAX_PURCHASE_PER_UNIT ? true : false
                        }
                    >
                        <AddIcon />
                    </IconButton>
                    {props.quantity === MAX_PURCHASE_PER_UNIT ? (
                        <HMAlert 
                            height='40px'
                            warning={true}
                            margin='auto 0 auto 10px'
                            text='Your reached maximum allowable purchase limit!'
                        />
                    ) : stockDetail.stockCount === props.quantity ? (
                        <HMAlert 
                            error={true}
                            height='40px'
                            margin='auto 0 auto 10px'
                            text='Maximum stock reached! Try another store'
                        />
                    ) : <></>}
                </HMBox>
                <HMBox 
                    width='100%'
                    display='flex'
                    padding='5px 0'
                    className='fade-in'
                    position='relative'
                    margin='auto auto 0 auto'
                >
                    <HMButton 
                        width='50%'
                        color={white}
                        bgColor={blue}
                        text='Add to cart'
                        handleClick={props.handleAddProductToCart}
                    />
                    <HMButton 
                        width='50%'
                        color={white}
                        text='Buy now'
                        bgColor={orange}
                        handleClick={props.handleBuyNow}
                    />
                </HMBox>
            </Grid>
            <Grid item xs={12} sm={12}>
                {!isHealth ? (
                    <ProductDetail  
                        isList={true}
                        text={sideEffects}
                        title='Side Effects'
                        margin='10px auto auto auto'
                    />
                ) : <></>}
                <ProductDetail 
                    text={howToUse}
                    title='How To Use'
                />
                <ProductDetail 
                    text={description}
                    title='Description'
                    margin='10px auto auto auto'
                />
            </Grid>
        </Grid>
    )
}

export default HMProduct