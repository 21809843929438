import apiClient from '../apiClient'

const API_URL_CART = '/api/v1/carts'

// Get cart
const getCart = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await apiClient.get(API_URL_CART, config)

    const data = response.data
    
    return data
}

// Get cart
const addCart = async (cartItem, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await apiClient.post(`${API_URL_CART}/add`, cartItem, config)

    const data = response.data
    
    return data
}

// Update cart
const updateCart = async (updatedCart, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    
    const response = await apiClient.post(`${API_URL_CART}/update`, updatedCart, config)

    const data = response.data

    return data
}

const cartService = {
    getCart,
    addCart,
    updateCart,
}
export default cartService